import { UNDERMAINTENANCE } from "../Constants";


export const fetchUnderMaintenance = async () => {
    try {
        const response = await fetch(UNDERMAINTENANCE, {
          method: "POST",
          headers: { "Content-Type": "application/json" }, 
          body: JSON.stringify({
            UnderMaintenance:''
            
          }),        
        });

        if (!response.ok) {
          throw new Error(`Failed to Maintenance! Status: ${response.status}`);
        }

        const result = await response.json();
       
        
      return result.UnderMaintenance;
    
    } catch (error) {
     console.log(error.message);
    }
}

export default fetchUnderMaintenance


import React, { createContext, useContext, useReducer } from 'react';

// Define an in-memory cart storage variable
let cartItems = [];

export const CartContext = createContext();

const cartReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_TO_CART':
      const existingItemIndex = cartItems.findIndex(item => item.id === action.payload.id);

      if (existingItemIndex !== -1) {
        cartItems[existingItemIndex].quantity += action.payload.quantity;
      } else {
        cartItems.push(action.payload);
      }
      return { ...state, cartItems: [...cartItems] };

    case 'REMOVE_FROM_CART':
      cartItems = cartItems.filter(item => item.id !== action.payload.id);
      return { ...state, cartItems: [...cartItems] };

    case 'UPDATE_QUANTITY':
      cartItems = cartItems.map(item =>
        item.id === action.payload.id ? { ...item, quantity: action.payload.quantity } : item
      );
      return { ...state, cartItems: [...cartItems] };

    case 'CLEAR_CART':
      cartItems = [];
      return { ...state, cartItems: [...cartItems] };

    default:
      return state;
  }
};

const initialState = {
  cartItems: [],
};

export const CartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(cartReducer, initialState);

  return (
    <CartContext.Provider value={{ cartItems: state.cartItems, dispatch }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
};

import "./popUp.css";
import ConfirmIcon from "../../../../assests/icons/confirmIcon.svg";
import { useEffect, useState } from "react";
import OTPInput from "./OTPInput";

import CustomLoader from "../../../common/customLoader/customLoader";
const ConfirmCOD = ({
  setOTPVerified,
  setconfirmCODPopUp,
  setLoadingCOD,
  isLoadingCOD,
}) => {
 
  const [generatedOtp, setGeneratedOtp] = useState();
  const [wrongOtp,setWrongOtp] = useState(false);

  function generateRandom4DigitText() {
    // Generate a random number between 0 and 9999
    const randomNumber = Math.floor(Math.random() * 10000);

    // Convert the number to a string and pad it with leading zeros
    const random4DigitText = randomNumber.toString().padStart(4, "0");

    return random4DigitText;
  }
  var generateOtp;
  let successfullmodal = document.getElementById("successfullModal");
  useEffect(() => {
    generateOtp = generateRandom4DigitText();
  
    setGeneratedOtp(generateOtp);
  }, []);


  const handleConfirm = () => {
  
    if (otpp.join("") == generatedOtp) {
   
      setWrongOtp(false)
      setOTPVerified(true);
      setLoadingCOD(true);
      setconfirmCODPopUp(false);
    } else if(otpp.join("") !== generatedOtp || otpp.join("").length !==4 ) {
      
      setWrongOtp(true)
 
    }
  };

  const handleCancel = () => {
    setOTPVerified(false);
    setconfirmCODPopUp(false);
    successfullmodal.style.display = "none";
  };
  const [otpp, setOtp] = useState(Array(4).fill(""));

  const handleOtpChange = (index, value) => {
    if(index == 0){
      setOtp(Array(4).fill(""));
    

    }
    setWrongOtp(false)
    const newOtp = [...otpp];
    newOtp[index] = value;
    //console.log("OTP ",newOtp);
    setOtp(newOtp);
  };
  //console.log(isLoadingCOD);
  return (
    <div className="confirmCOD-modalWrapper">
      {isLoadingCOD ? (
        <div className=" confirmCOD-modalContent ">
          <CustomLoader />
        </div>
      ) : (
        <div className="confirmCOD-modalContent">
          <img src={ConfirmIcon} className="confirmIcon" alt="Confirm Image" />
          <div className="confirmText">Confirm Your Order</div>
          <div className="OTPText">{generatedOtp}</div>
          <div className="otpWrapper">
            <OTPInput length={4} onChange={handleOtpChange} setOtp={setOtp} />
          </div>
          {wrongOtp && otpp.join("").length == 4 && <div className="red">Please enter correct number</div>}
          {wrongOtp && otpp.join("").length !==4 && <div className="red">Please enter number</div> }
          <div className="buttons">
            <button className="home-button" onClick={handleConfirm}  id="confirm-button">
              Confirm
            </button>
            <button className="order-button" onClick={handleCancel}>
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
export default ConfirmCOD;

import React, { useState, useEffect } from "react";
import { fetchBannerData } from "../../../../api/HomePageApi";

const AnnoucementBar = () => {
  const [announcementData, setAnnouncementData] = useState([]);

  useEffect(() => {
    let isMounted = true; 
    const loadBannerData = async () => {
      try {
        const data = await fetchBannerData();
        const banners = data || [];
        setAnnouncementData(data);
        
      } catch (error) {
        console.error('Error fetching banner data:', error);
      } finally {
        // console.error('Error fetching banner data:', error);
      }
    };

    loadBannerData();

    return () => {
      isMounted = false; 
    };
  }, []); 
  return (
    <div className="header__announcement-bar">
      <div className="header__announcement-container">
        <div className="header__announcement-wrapper">
          <div className="announcement__content">{announcementData.ShippingText}</div>
        </div>
      </div>
    </div>
  );
};

export default AnnoucementBar;
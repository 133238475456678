import React from "react";

const InviteSection = () => {
    return(
        <div className="invite__section__wrapper">
            <div className="invite__section__container page-width">
            </div>
        </div>
    )
}

export default InviteSection;
import React from "react";
import '../homeIcons/hmIconGrid.css';

const IconsContainer = [
  { 
    src: '/images/1.png',
    text: '100% original',
    alt: 'New Image 1' 
  },
  { 
    src: '/images/2.png',
    text: 'Certified by Brands',
    alt: 'New Image 2' 
  },
  { 
    src: '/images/3.png',
    text: 'Secure Packing',
    alt: 'New Image 3' 
  },
  { 
    src: '/images/4.png',
    text: 'Fast Delivery',
    alt: 'New Image 3' 
  }
];

const HomeIconsItem = () => {
  return (
    <div className="hm__icon__grid-wrapper">
      <div className="hm__icon__grid-container page-width">
        {IconsContainer.map((item, index) => (
          <div key={index} className="hm__icon__item">
            <div className="hm__icon__image">
              <img src={item.src} alt={item.alt} />
            </div>
            <div className="hm__icon__content">
              <h3>{item.text}</h3>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default HomeIconsItem;

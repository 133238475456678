import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../homeSlider/hmSlider.css';
import Loader from '../../loader/loadingCard';
import { fetchBannerData } from '../../../../api/HomePageApi';
import { Link } from 'react-router-dom';
// import befitvideo from "../../../../assests/video/befitvideo.webm";
import moengage from "@moengage/web-sdk";
import Cookies from "js-cookie";

const DefaultSliderImages = [
  {
    mobileSrc: '/images/mobile-banner.png',
    desktopSrc: '/images/banner1.png',
    alt: 'New Image 1'
  },
  {
    mobileSrc: '/images/mobile-banner.png',
    desktopSrc: '/images/banner1.png',
    alt: 'New Image 2'
  },
  {
    mobileSrc: '/images/mobile-banner.png',
    desktopSrc: '/images/banner1.png',
    alt: 'New Image 3'
  },
  {
    mobileSrc: '/images/mobile-banner.png',
    desktopSrc: '/images/banner1.png',
    alt: 'New Image 4'
  },
  {
    mobileSrc: '/images/mobile-banner.png',
    desktopSrc: '/images/banner1.png',
    alt: 'New Image 5'
  }
];

const HmSlider = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [sliderImages, setSliderImages] = useState(DefaultSliderImages);
  const [bannerData, setBannerData] = useState([]);
  // const [videoLoading, setVideoLoading] = useState(true);
  // const videoRef = useRef(null);

  // useEffect(() => {
  //   if (videoRef.current) {
  //     videoRef.current.play();
  //   }
  // }, []);

  useEffect(() => {
    const loadBannerData = async () => {
      try {
        const data = await fetchBannerData();
        const banners = data.TopBanner && data.TopBanner.length > 0 ? data.TopBanner : [];

        if (banners.length > 0) {
          const formattedImages = banners.map(banner => ({
            mobileSrc: banner.MobileImage?.trim() || '/images/default-mobile.png',
            desktopSrc: banner.Image?.trim() || '/images/default-desktop.png',
            alt: banner.alt || 'Default Image'
          }));
          setSliderImages(formattedImages);
        } else {
          // Use default images if no banners are found
    
          setSliderImages(DefaultSliderImages);
        }

        setBannerData(data);
        setIsLoading(false);
        setImagesLoaded(true);
      } catch (error) {
        console.error('Error fetching banner data:', error);
        // If fetching fails, fall back to default images
        setSliderImages(DefaultSliderImages);
        setIsLoading(false);
        setImagesLoaded(true);
      }
    };

    loadBannerData();
  }, []);

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    touchThreshold: 100
  };

  const handleBannerClick = (name,url,index) => {
    if(Cookies.get("Otp")){
      moengage.track_event("HomePage_TopBanner_Viewed", {
        "Phone No": `+91${JSON.parse(Cookies.get("Otp")).MobileNo}` , 
      "Brand Name" : name,
    "Banner URL": url,
    "Index" : index
         });
    }
    
    
  }

  return (
    <div className="hm__slider__wrapper">
      {isLoading && <Loader />}
      {!isLoading && imagesLoaded && (
        <div className="hm__slider__container page-width padding-lr20">
          <Slider {...settings}>
            {sliderImages.map((image, index) => (
              <div key={index} className="hm__slide__item">
                <Link onClick={() => handleBannerClick(bannerData.TopBanner[index]?.BrandName,image.desktopSrc,index)} to={`/brands/` + `${(bannerData.TopBanner[index]?.BrandName || 'default-brand')}`.replace(/[^a-zA-Z0-9 ]/g, "").replace(/\s+/g, "-").toLowerCase() + `?id=${bannerData.TopBanner[index]?.TypeId || 0}`}>
                  <picture>
                    <source media="(min-width: 768px)" srcSet={`${image.desktopSrc} 768w, ${image.desktopSrc} 1024w`} />
                    <img className="desktop-img" src={image.desktopSrc} alt={image.alt} />
                    <source media="(max-width: 767px)" srcSet={`${image.mobileSrc} 320w, ${image.mobileSrc} 640w`} />
                    <img className="mobile-img" src={image.mobileSrc} alt={image.alt} />
                  </picture>
                </Link>
              </div>
            ))}
          </Slider>
        </div>
      )}
    </div>
  );
};

export default HmSlider;

import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { fetchRecommenedBlogListData } from '../../../api/BlogApi';
import "./blog.css";

const BlogPosts = ({post_id}) => {
  const [loading, setLoading] = useState(false);
  const [blogData, setBlogData] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const loadRecommendedData = async () => {   
      setLoading(true); 
    let   recommendedBlog = await fetchRecommenedBlogListData();  

    recommendedBlog = recommendedBlog.BlogPost.filter((post) => post.id !== parseInt(post_id,10) ) 
     setBlogData(recommendedBlog);
      setLoading(false);
  };

  loadRecommendedData();
  }, [post_id]);
 

  const handleReadMoreClick = (post) => {
    const url = `/blog/${post.Title
      .replace(/[^a-zA-Z0-9\s]/g, '')
      .replace(/\s+/g, '-')
      .toLowerCase()}?post_id=${post.id}`;
  
    // Navigate to the new URL
    navigate(url);
    
    // Add the 'active' class to show the loader
    const container = document.querySelector(".opacity__main__container");
    if (container) {
        container.classList.add("active");
    }

    // Scroll to the top of the page
    window.scrollTo({
        top: 0,
        behavior: 'smooth' 
    });

    // Remove the 'active' class after a delay
    setTimeout(() => {
        if (container) {
            container.classList.remove("active");
        }
    }, 1000);
};


  return (
    <div className="blog__post__wrapper blog__recommendation">
      <div className="blog__post__container">
        <div className="blog__post__heading">You May Also Like</div>

        <div className="blog__post__content blog__post__main__content">
          {loading ? (
            <div className="loader">Loading...</div>
          ) : (
            blogData.map(post => (
              <Link
              to={`/blog/${post.Title
                .replace(/[^a-zA-Z0-9\s]/g, '')
                .replace(/\s+/g, '-')
                .toLowerCase()}?post_id=${post.id}`}
                key={post.id}
            >
              <div key={post.id} className="blog__card">
                <img src={post.WebImage} alt={post.Title} className="blog__card__image" />
                <div className="blog__card__content">
                  <div className="blog__card__header">
                    <div className="blog__author__details">
                      <img src={post.UserProfile} alt={post.UserName} className="blog__author__image" />
                      <div className="blog__author__name">{post.UserName}</div>
                    </div>
                    <span className="blog__card__category">{post.BlogCategory}</span>
                  </div>
                  <div className="blog__card__meta">
                    <span className="blog__card__date">{post.PublishedAt}</span>
                  </div>
                  <h2 className="blog__card__title">{post.Title}</h2>
                  <p className="blog__card__description" dangerouslySetInnerHTML={{
                      __html: post.WebContent.length > 100 ? post.WebContent.slice(0, 100) + '...' : post.WebContent,
                    }}>
                 
                  </p>    
                  <button
                    onClick={() => handleReadMoreClick(post)}
                    className="blog__card__read-more youmay__like"
                  >
                    Read More
                  </button>
                </div>
              </div>
              </Link>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default BlogPosts;

// src/api/MetadataApi.js

import { META_DATA } from "../Constants";

const BASE_URL = META_DATA;

let cachedData = null; // Cache to store fetched metadata
let fetchPromise = null; // Promise to track ongoing fetch request

/**
 * Fetch metadata (e.g., banner data) from the server with caching.
 *
 * @returns {Promise<Object>} - A promise that resolves with the fetched metadata.
 */
export const fetchMetadata = async () => {
  if (cachedData) return cachedData;
  if (fetchPromise) return fetchPromise;

  fetchPromise = (async () => {
    try {
      const payload = new URLSearchParams(); // Adjust based on actual data needs

      const response = await fetch(BASE_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: payload.toString(),
      });

      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

      const data = await response.json();
      cachedData = data || []; // Cache the fetched data
      return cachedData;
    } catch (error) {
      console.error('Error fetching metadata:', error);
      throw error;
    } finally {
      fetchPromise = null; // Clear the fetch promise once completed
    }
  })();

  return fetchPromise;
};

// // Meta Tag API Data Fetch
// const fetchMetadataApi = async () => {
//   try {
//     const metaApi = await fetchMetadata();
//     if (metaApi && metaApi.Result) {
//   
//     } else {
//       console.warn('Metadata API returned no results.');
//     }
//   } catch (error) {
//     console.error('Error fetching metadata:', error);
//   }
// };

// fetchMetadataApi();

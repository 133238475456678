import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Breadcrumbs from "../../common/breadcrumb/breadcrumb";
import { Link } from 'react-router-dom';
import { fetchBlogListData } from '../../../api/BlogApi';
import "./blog.css";

const RecommendatedBlogs = ({recommended}) => {

  return (
    <div className="blog__post__wrappers blog__recommendation">
      <div className="blog__post__container">
        <div className="blog__post__heading">Recommend Blogs </div>
        <div className="blog__post__content">
          {!recommended ? (
            <div className="loader">Loading...</div>
          ) : (
            recommended.map(post => (
              <Link
              key={post.id}
              to={`/blog/${post.Title
                .replace(/[^a-zA-Z0-9\s]/g, '')
                .replace(/\s+/g, '-')
                .toLowerCase()}?post_id=${post.id}`}
             
            >
              <div key={post.id} className="blog__card">
                <img src={post.WebImage} alt={post.Title} className="blog__card__image" />
                <div className="blog__card__content">
                  <div className="blog__card__header">
                    <div className="blog__author__details">
                      <img src={post.UserProfile} alt={post.UserName} className="blog__author__image" />
                      <div className="blog__author__name">{post.UserName}</div>
                    </div>
                    <span className="blog__card__category">{post.BlogCategory}</span>
                  </div>
                  <div className="blog__card__meta">
                    <span className="blog__card__date">{post.PublishedAt}</span>
                  </div>
                  <h2 className="blog__card__title">{post.Title}</h2>
                  <p className="blog__card__description" dangerouslySetInnerHTML={{
                      __html: post.WebContent.length > 100 ? post.WebContent.slice(0, 100) + '...' : post.WebContent,
                    }}>
                    {/* {post.Content.length > 200 ? post.Content.slice(0, 200) + '...' : post.Content} */}
                  </p>    
                  {/* <Link
                    to={`/blog/${post.Title
                      .replace(/[^a-zA-Z0-9\s]/g, '')
                      .replace(/\s+/g, '-')
                      .toLowerCase()}?post_id=${post.id}`}
                    className="blog__card__read-more" 
                  >
                    Read More
                  </Link> */}
                  <div  className="blog__card__read-more"  >Read More</div>
                </div>
              </div>
              </Link>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default RecommendatedBlogs;

import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LoadingCard from '../loader/loadingCard';
import {BrandList} from '../../common/brands/brandApi'; // Adjust the import path as necessary

const LogoListSlider = () => {
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const brandData = await BrandList();
        const flattenedBrands = Object.values(brandData).flat();
        setBrands(flattenedBrands);
      } catch (error) {
        console.error("Error fetching brands:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBrands();
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 5000,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: 'linear',
    slidesToShow: 7,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className="logolist__wrapper section_space">
      <div className='page-width'>
        <div className="hm__prod__slider-title less_space_title">
          <h2>New Brand Launches</h2>
        </div>
      </div>
      <div className='infinity_brand_icon'>
        {loading ? (
          <div className="loading-cards">
            <LoadingCard />
            <LoadingCard />
            <LoadingCard />
            <LoadingCard />
            <LoadingCard />
            <LoadingCard />

          </div>
        ) : (
          <Slider {...settings} className="logolist__items">
            {brands.map((brand, index) => (
              <div key={index} className="logolist__item">
                <img src={brand.logo} alt={brand.name} /> {/* Assuming 'logo' property */}
              </div>
            ))}
          </Slider>
        )}
      </div>
    </div>
  );
};

export default LogoListSlider;

import React from "react";
import RemoveIcon from "../../assests/icons/Delete.svg";

const RemoveBtn = () => {
  return (
    <div className="remove__button__container">
      <div className="remove__button">
        <div className="remove__btn__icon">
          <img src={RemoveIcon} alt="plus"/>
          <span>Remove</span>
        </div>
      </div>
    </div>
  );
};

export default RemoveBtn;

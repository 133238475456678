import React, { useState, useCallback } from "react";
import PlusIcon from "../../assests/icons/plus.svg";
import MinusIcon from "../../assests/icons/minus.svg";
import { toast } from "react-toastify";
import debounce from "lodash.debounce"; // Add lodash.debounce for debouncing

const PlusMinus = ({ product, quantity, onChange }) => {
  const [localQuantity, setLocalQuantity] = useState(quantity || 1);
  const [toastShown, setToastShown] = useState(false);

  const debouncedIncrementQuantity = useCallback(
    debounce((newQuantity) => {
      setLocalQuantity((prevQuantity) => {
        if (newQuantity <= 4) {
          if (onChange) onChange(newQuantity);
          setToastShown(false);
          return newQuantity;
        } else {
          if (!toastShown) {
            setToastShown(true);
            toast("Cannot add more than 4 Quantity");
          }
          return prevQuantity;
        }
      });
    }, 100), // Adjust debounce time as needed
    [toastShown, onChange]
  );

  const incrementQuantity = () => {
    setLocalQuantity((prevQuantity) => {
      const newQuantity = prevQuantity + 1;
      debouncedIncrementQuantity(newQuantity);
      return prevQuantity; // Return the previous quantity to avoid premature updates
    });
  };

  const decrementQuantity = () => {
    setLocalQuantity((prevQuantity) => {
      if (prevQuantity > 1) {
        const newQuantity = prevQuantity - 1;
        if (onChange) onChange(newQuantity);
        return newQuantity;
      }
      return prevQuantity;
    });
  };

  const handleQuantityChange = (event) => {
    const newQuantity = parseInt(event.target.value, 10);
    if (newQuantity > 0) {
      if (newQuantity < 5) {
        setLocalQuantity(newQuantity);
        setToastShown(false);
        if (onChange) onChange(newQuantity);
      } else {
        if (!toastShown) {
          setToastShown(true);
          toast("Cannot add more than 4 Quantity");
        }
      }
    }
  };

  return (
    <div className="quantity__selector__container">
      <label className="quantity__selectors heading option__name">Quantity</label>
      <div className="quantity__selector">
        <div
          className="plus__btn"
          onClick={incrementQuantity}
          disabled={localQuantity >= 4}
        >
          <img src={PlusIcon} alt="plus" />
        </div>
        <div className="input__value" data-value={localQuantity}>
          <input
            type="number"
            value={localQuantity}
            onChange={handleQuantityChange}
            min="1"
            className="quantity__input"
            disabled
          />
        </div>
        <div
          className="minus__btn"
          onClick={decrementQuantity}
          disabled={localQuantity === 1}
        >
          <img src={MinusIcon} alt="minus" />
        </div>
      </div>
    </div>
  );
};

export default PlusMinus;

// utils/api.js
import { PRODUCTLIST } from "../Constants";

export const fetchProducts = async (categoryId,categoryName, page) => {
let payload;
   payload = new URLSearchParams({
    Categoryid: categoryId ,
    CategoryId: categoryId,//CategoryName: categoryName,
    ParentCategoryName:"",
    groupCategoryId: '0',
    Pagination: page.toString()
  });
  
    const response = await fetch(PRODUCTLIST, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: payload
    });
  
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
  
    const data = await response.json();
    return data.Result[0] || [];
  };
  
// src/pages/Home.js
import React, { useState, useEffect } from 'react';
import HmSlider from '../components/common/bannerSlider/homeSlider/hmSlider';
import ProductSlider from '../components/common/productSlider/productSlider';
import BannerSlider from '../components/common/bannerSlider/offerBannerSlider/offerBannerCard';
import ProductSliderColor from '../components/common/productSlider/productSlider2';
import TopBrands from '../components/common/brandGrid/brandGrid';
import IconsContainer from '../components/common/homeIcons/hmIconsGrid';
import AmazingBrand from '../components/common/bannerSlider/amazingBrand/amazingBrand';
import WeightManagement from '../components/common/productSlider/weightManagement';
import AppealsOffers from '../components/common/productSlider/appealsOffer';
import BestSellerSlider from "../components/common/productSlider/bestSeller";
import DealsOfDay from "../components/common/bannerSlider/dailyDeals/dailyDeals";
import KidsNutritions from "../components/common/productSlider/kidsNutritions";
import FunctionalFoods from "../components/common/productSlider/functionalFood";
import ImageSection from "../components/common/imageSection/imageSection";
import ExtraProductCardSlider from "../components/common/productSlider/extraProductSlider";
import LogoListSlider from '../components/common/logoList/logoList';
import InviteSection from "../components/common/inviteSection/inviteSection";
import { fetchCategoryFromApi } from "../components/layout/category/categoryApi";
import ToastMessage from '../components/common/toastMessage/toastMessage';
import MetaTagsComponent from '../components/layout/metaTag/metaTagComp';

const Home = ({ setCountItems }) => {
  const [categories, setCategories] = useState({});
  const [isToastMessage, setIsToastMessage] = useState(false);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const cateGoryApi = await fetchCategoryFromApi();
        if (cateGoryApi && cateGoryApi.ParentCategory) {
          const { ParentCategory } = cateGoryApi;
          // Transform ParentCategory into an object for easier access
          const categoriesObj = {};
          ParentCategory.forEach(cat => {
            categoriesObj[cat.id] = cat.parent_category_name;
          });
          setCategories(categoriesObj);
        }
      } catch (error) {
        console.error('Error fetching category data:', error);
      }
    };

    fetchData();
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MetaTagsComponent />
      <HmSlider />

      <DealsOfDay />

      <BestSellerSlider 
        categoryName={"Best Sellers"} 
        setIsToastMessage={setIsToastMessage}
        setCountItems={setCountItems}
      />

      <AmazingBrand />

      <ExtraProductCardSlider 
        categoryName={categories[6]} 
        categoryId={6} 
        setIsToastMessage={setIsToastMessage}
        setCountItems={setCountItems}
      />

      <BannerSlider />

      <IconsContainer />

      <TopBrands />

      <ProductSlider 
        setCountItems={setCountItems} 
        categoryName={categories[1]} 
        categoryId={1} 
        setIsToastMessage={setIsToastMessage}
        
      />
       <ProductSlider 
        setCountItems={setCountItems} 
        categoryName={categories[2]} 
        categoryId={2} 
        setIsToastMessage={setIsToastMessage}
        
      />
      
      <ProductSliderColor 
        categoryName={categories[4]} 
        categoryId={4} 
        setIsToastMessage={setIsToastMessage}
        setCountItems={setCountItems} 
      />

      <KidsNutritions 
       setCountItems={setCountItems}
        categoryName={categories[5]} 
        categoryId={5} 
        setIsToastMessage={setIsToastMessage}
      />

      <WeightManagement 
       setCountItems={setCountItems}
        categoryName={categories[3]} 
        categoryId={3} 
        setIsToastMessage={setIsToastMessage}
      />
  <WeightManagement 
   setCountItems={setCountItems}
        categoryName={categories[6]} 
        categoryId={6} 
        setIsToastMessage={setIsToastMessage}
      />
      <FunctionalFoods 
       setCountItems={setCountItems}
        categoryName={categories[7]} 
        categoryId={7} 
        setIsToastMessage={setIsToastMessage}
      />

      <AppealsOffers 
       setCountItems={setCountItems}
        categoryName={categories[8]} 
        categoryId={8} 
        setIsToastMessage={setIsToastMessage}
      />

      <LogoListSlider />

      <ImageSection />
      <InviteSection />
      {isToastMessage && <ToastMessage />}

    </>
  );
};

export default Home;

import React, { useEffect, useState } from "react";
import { fetchRegisterId, WISH_LIST_PRODUCTLIST } from "../../../../Constants";
import ProductCard from "../../product/productCard/productCard";

const WishList = () => {

    const [wishList,setWishList] = useState([])

  useEffect(() => {
    fetchWishListProducts();
  }, []);

  const fetchWishListProducts = async () => {
    try {
      const response = await fetch(WISH_LIST_PRODUCTLIST, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          RegisterId: fetchRegisterId(),
        }),
      });

      if (!response.ok) {
        throw new Error(
          `Failed to fetch cart items! Status: ${response.status}`
        );
      }

      const result = await response.json();
      console.log(result.Result[0]);
      setWishList(result.Result[0])
    } catch (error) {
      console.log(error.message);
    }
  };

console.log(wishList)
  return <div className="wishlist-wrapper">
     
    {wishList && wishList?.map((list) => (
       <ProductCard product={list}/>
    ))}
    </div>

};
export default WishList;

export const validateAccountForm = (formData,setErrors) => {
    let valid = true;
    let errors = { firstName: '',lastName:'', email: '' };
    if (!formData.firstName || !formData.firstName.trim()) {
      errors.firstName = 'Required';
      valid = false;
    }
    if (!formData.lastName || !formData.lastName.trim()) {
        errors.lastName = 'Required';
        valid = false;
      }
    

    if (!formData.email) {
      errors.email = 'Required';
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Invalid';
      valid = false;
    }
    setErrors(errors);
    return valid;

}



export const validateAddressForm = (formData,setErrors) => {
    let valid = true;
    const errors = {};
    if (!formData.building || !formData.building.trim()) {
        errors.building = 'Required';
        valid = false;
      }
    if (!formData.street || !formData.street.trim()) {
      errors.street = 'Required';
      valid = false;
    }
    if (!formData.city ) {
      errors.city = 'Required';
      valid = false;
    }
    if (!formData.state) {
      errors.state = 'Required';
      valid = false;
    }
    if (!formData.pincode ){
        errors.pincode = 'Required';
        valid = false;
    }else if( !/^\d{6}$/.test(formData.pincode)) {
      errors.pincode = 'Invalid';
      valid = false;
    }
  
    setErrors(errors);
    return valid;


}
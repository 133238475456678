import React from 'react'
import { Link } from 'react-router-dom'
import { check_mark } from '../../../utils/image'

export default function ToastMessage() {
    return (
        <div className='toast_msg'>
            <div className='toast_mesg_wrap'>
                <img src={check_mark} alt="" />
                <h4>Item added to cart</h4>
                <Link className='btn_primary' to="/cart">
                    Cart
                </Link>
            </div>
        </div>
    )
}

import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as SearchIcon } from "../../../assests/icons/Search.svg";
import SearchIcons from "../../../assests/icons/Search.svg";
import "./searchBar.css";
import Cookies from "js-cookie";
import debounce from 'lodash/debounce';
import { replaceReplacementChar, SEARCH_API,SEARCH_DETAIL } from "../../../Constants";
import moengage from "@moengage/web-sdk";
// Function to fetch keyword suggestions
const SearchKeyword = async (searchName) => {
  try {
    const url = SEARCH_API;
    const payload = new URLSearchParams({ SearchName: searchName });

    const response = await fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: payload,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data.Result;
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

// Function to fetch detailed keyword suggestions
const SearchKeywordDetails = async (searchName) => {
  if(Cookies.get("Otp")){
  moengage.track_event("Search_Bar_Viewed", {
    "Phone No": `+91${JSON.parse(Cookies.get("Otp")).MobileNo}` ,  
    "Value Entered": searchName,
    

    });
  }


  try {
    const url = SEARCH_DETAIL;
    const payload = new URLSearchParams({ SearchName: searchName });

    const response = await fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: payload,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data.Result;
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

const SearchBar = ({ setDisplayProductList }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [detailedResults, setDetailedResults] = useState([]);
  const searchListRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  // clear url //
  useEffect(() => {
    if(location.pathname === "/"){
      const searchInputs = document.querySelectorAll("input.search-input");
      searchInputs.forEach(input => {
        input.value = "";
      });
    
    }
  }, [location]);


  // Debounced API call for suggestions
  const debouncedFetchSuggestions = useRef(
    debounce(async (searchTerm) => {
      const result = await SearchKeyword(searchTerm);
      setSuggestions(result);
    }, 300) // 300ms debounce delay
  ).current;

  // Load initial suggestions
 
    const fetchInitialData = async () => {
      // Optionally fetch suggestions for an empty search term
      const initialSuggestions = await SearchKeyword("");
      setSuggestions(initialSuggestions);
    };

  

  useEffect(() => {
    if (searchTerm) {
      debouncedFetchSuggestions(searchTerm);
    } else {
      setSuggestions([]);
    }
  }, [searchTerm]);

  useEffect(() => {
    if (searchTerm) {
      const fetchDetails = async () => {
        const result = await SearchKeywordDetails(searchTerm);
        setDetailedResults(result); // Store detailed results in state
        setDisplayProductList(result); // Pass data to the parent component
      };
      fetchDetails();
    } else {
      setDetailedResults([]);
    }
  }, [searchTerm, setDisplayProductList]);

  const handleFocus = () => {
    fetchInitialData();
    if (searchListRef.current) {
      searchListRef.current.style.display = "block";
      searchListRef.current.classList.add("active"); // Add class on focus
    }
  };

  const handleBlur = () => {
    // Delay hiding to avoid flickering
    setTimeout(() => {
      if (searchListRef.current) {
        searchListRef.current.classList.remove("active"); // Remove class on blur
        searchListRef.current.style.display = "none";
      }
    }, 500);
  };

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSuggestionClick = (name, href) => {
    setSearchTerm(name);
    setSuggestions([]);
    setDetailedResults([]);
    setTimeout(() => {
      if (searchListRef.current) {
        searchListRef.current.style.display = "none";
      }
    }, 500);

    navigate(`${href}`)?.replace(/[^a-zA-Z0-9\s-]/g, "")
      .replace(/\s+/g, "+")
      .replace(/-$/, "")
      .replace(/--+/g, "+")
      .replace(/ /g, "+")
      .toLowerCase(); // Navigate to the href path
  };

  const formattedQuery = (query) =>
    encodeURIComponent(
      query
        .replace(/[^a-zA-Z0-9\s-]/g, "")
        .replace(/\s+/g, "+")
        .replace(/-$/, "")
        .replace(/--+/g, "+")
        .replace(/ /g, "+")
        .toLowerCase()
    );

  return (
    <div className="header__searchbar">
      <input
        type="text"
        placeholder="Search on unFIT Mart"
        className="search-input"
        onFocus={handleFocus}
        onBlur={handleBlur}
        value={searchTerm}
        onChange={handleChange}
        onKeyPress={(event) => {
          if (event.key === "Enter") {
            event.preventDefault();
            handleSuggestionClick(searchTerm, `/products/search?query=${searchTerm.replace(/[^a-zA-Z0-9\s-]/g, "")
              .replace(/\s+/g, "+")
              .replace(/-$/, "")
              .replace(/--+/g, "+")
              .replace(/ /g, "+")
              .toLowerCase()}`);
          }
        }}
      />
      <img src={SearchIcons} alt="search" className="icon icon-search" />
      <div className="nav_sub_menu" id="searchList" ref={searchListRef}>
        {suggestions.length > 0 && (
          <>
            <ul className="suggestions-list">
              <li className="suggestions-label">Suggestions</li>
              {suggestions.map((suggestion) => (
                <Link
                  key={`${suggestion.id}-${suggestion.name}`}
                  to={`/products/search?query=${suggestion.name.replace(/[^a-zA-Z0-9\s-]/g, "")
                    .replace(/\s+/g, "+")
                    .replace(/-$/, "")
                    .replace(/--+/g, "+")
                    .replace(/ /g, "+")
                    .toLowerCase()}`}
                >
                  <li
                    onClick={() =>
                      handleSuggestionClick(suggestion.name, `/products/search?query=${suggestion.name.replace(/[^a-zA-Z0-9\s-]/g, "")
                        .replace(/\s+/g, "+")
                        .replace(/-$/, "")
                        .replace(/--+/g, "+")
                        .replace(/ /g, "+")
                        .toLowerCase()}`)
                    }
                    className="searchList"
                  >
                    <SearchIcon />
                    <div>{replaceReplacementChar(suggestion?.name) }</div>
                  </li>
                </Link>
              ))}
            </ul>
          </>
        )}

        {detailedResults.length > 0 && (
          <>
            <ul className="detailed-results-list">
              <li className="detailed-results-label">Products</li>
              {detailedResults.map((result) => (
                <Link
                  key={result.id}
                  to={`/product?type=productvariant&productid=${result.id}&variant1Value=${result.Variant1Value}&variant2Value=${result.Variant2Value}`}
                >
                  <li
                    onClick={() => handleSuggestionClick(result.name, `/product?type=productvariant&productid=${result.id}&variant1Value=${result.Variant1Value}&variant2Value=${result.Variant2Value}`)}
                    className="searchList"
                  >
                    <img src={result.Image} alt={result.name} className="search__image" />
                    {result.name.length > 50
                      ? replaceReplacementChar(result?.name.substring(0, 55)) + "..."
                      : replaceReplacementChar(result?.name) }
                  </li>
                </Link>
              ))}
            </ul>
          </>
        )}
      </div>
    </div>
  );
};

export default SearchBar;

import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import ProductCard from '../../layout/product/productCard/brandPlacementCard';
import '../../layout/product/productCard/productCard.css';
import Loader from '../loader/loadingCard';
// import { HmProdPrevArrow, HmProdNextArrow } from '../bannerSlider/homeSlider/customArrow/customArrow';
// import { fetchProducts } from '../../../api/prodApi';
import { fetchBannerData } from '../../../api/HomePageApi';
import { ReactComponent as LeftArrow } from '../../../assests/icons/LeftArrow.svg';
import { Link } from 'react-router-dom';
import ProductCard from '../../layout/product/productCard/productCard';

const ProductSlide = ({ categoryName, categoryId,setIsToastMessage,setCountItems }) => {
  // const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true); // State to track loading state
  // const [page, setPage] = useState(1); // State for pagination
  const isMounted = useRef(false);
  const [ bestSellerProd , setBestSeller] = useState([]);
  const [ bestSellerProdData , setBestSellerData] = useState([]);
  const [currentSelected, setCurrentSelected] = useState([]);


  const loadProducts = async () => {
    setLoading(true);
    try {
      const bestSellerProduct = await fetchBannerData();
      setBestSeller(bestSellerProduct.BestSellingProduct);
      setBestSellerData(bestSellerProduct);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false); // Ensure loading is false after data fetch or error
    }
  };

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true; // Set isMounted to true after the first render
      loadProducts(); // Call loadProducts once on initial mount
    }
  }, []);

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    touchThreshold: 100,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1

        }
      }
    ]
  };

  // let categoryReplace = decodeURIComponent(categoryName);

  const handleInitialAddToCart = (product) => {
    setCurrentSelected((prev) => {
      if (prev.includes(product?.id)) {
        return prev.filter((each) => each !== product?.id);
      } else {
        return [product?.id];
      }
    });
  };

  return (
    <div className="hm__product-container section_space hm__prod__slider">
      <div className=' page-width'>
        <div className="hm__prod__slider-title">
          <h2>{categoryName}</h2>
          <div className="hm__prod__desktop__arrow">
            <Link to={`/${"best-seller"}`}>View all</Link>
            </div>
          <div className="hm__prod__mobile__arrow">
            <Link to={`/${"best-seller"}`}><LeftArrow /></Link></div>
        </div>
      </div>

      <div className='slider_container cm_arrow_side'>
        {loading ? (
          <div className="loading-spinner">
            <div className='product-list'>
              <Loader />
              <Loader />
              <Loader />
              <Loader />
              <Loader />
            </div>
          </div> // Display loader while fetching data
        ) : (
          <Slider {...sliderSettings}>
            {bestSellerProd.map((product) => (
              // product.Type === "product" && (
                <ProductCard key={product.id} product={product} onAddtoCart={handleInitialAddToCart}  isOutOfStock={product?.PriceQuantity[0].InStock === "yes"? false : true}
                  currentSelected={currentSelected} note={product.PriceQuantity[0].Note} bestSellerProductData={bestSellerProdData} 
                  setIsToastMessage={setIsToastMessage} setCountItems={setCountItems} from={"Best_sellers_product"} />
             
              // )
            ))}
          </Slider>
        )}
      </div>
    </div>
  );
}

export default ProductSlide;

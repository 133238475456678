import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import RemoveButton from "../../common/removeButton";
import PlusIcon from "../../../assests/icons/plus.svg";
import MinusIcon from "../../../assests/icons/minus.svg";
import { PRODUCTDETAIL } from "../../../Constants";
import {
  WEB_CARTLIST,
  WEB_CART_DELETE,
  WEB_CART_QUANTITY_UPDATE,
  fetchRegisterId,
} from "../../../Constants";
import { toast } from "react-toastify";
import moengage from "@moengage/web-sdk";

const CartItem = ({
  onItemRemove = () => {},
  onUpdate = () => {},
  setCountItem,
  countItems,
  setItems,

  setCountItems,
  appliedCoupon
  
}) => {
  const [sessionError, setSessionError] = useState(null);
  const [toastShown, setToastShown] = useState(false);

  useEffect(() => {
    
    fetchCartItems();
  }, []);

  const fetchCartItems = async () => {
   

    try {
      let response;
      let registerId = fetchRegisterId();
   

      if (registerId) {
        response = await fetch(WEB_CARTLIST, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ RegisterId: registerId ,PaymentType:'Prepaid',ApplicationType:'web', CouponApplyCode: appliedCoupon, }),
        });
      } 

      if (!response.ok) {
        throw new Error(
          `Failed to fetch cart items! Status: ${response.status}`
        );
      }

      const cartItemsData = await response.json();
   
      setItems(cartItemsData || []);
      setCountItem(cartItemsData.Result);
      setCountItems(cartItemsData)  //sending random  just to render the useEffect function which will automatic render the count .No dependency on what value is being passed
    } catch (error) {
      setSessionError(error.message);
    }
  };

  const handleQuantityChange = async (newQuantity, itemId,item) => {
    try {

      const response = await fetch(WEB_CART_QUANTITY_UPDATE, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          RegisterId:fetchRegisterId(),
     
          Quantity: newQuantity,
          CartListId: itemId,
        }),
      });

      if (!response.ok) {
        throw new Error(
          `Failed to update quantity! Status: ${response.status}`
        );
      }
      console.log(newQuantity,item)


     
      moengage.track_event(`Update_Add_to_Cart`, {
        "Phone No": `+91${JSON.parse(Cookies.get("Otp")).MobileNo}`,
       
        "Product ID": item.ProductId,
        "Product Name": item.PorductName,
        "Base Price": item.PriceQuantity[0].Mrp,
        "Promotion Price": item.PriceQuantity[0].Price,
        "Discount Percentage": item.Note,
        "Flavour": item.variant1_value,
        "Size": item.variant2_value, 
        "Quantity":  newQuantity,       
        "Screen Name": "Cart Page ",
      });
      await fetchCartItems();
    } catch (error) {
      console.error("Error updating quantity or fetching cart list:", error);
    }
  };

  const handleRemoveClick = async (cartListId,item) => {
    try {
      const response = await fetch(WEB_CART_DELETE, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          CartListId: cartListId,
        }),
      });

      if (!response.ok) {
        throw new Error(
          `Failed to remove cart item! Status: ${response.status}`
        );
      }

      const updatedItems = countItems.filter((item) => item.id !== cartListId);
      setCountItem(updatedItems);
     
      if (typeof onItemRemove === "function") {
        onItemRemove(updatedItems.length);
      }
      if (typeof onUpdate === "function") {
        onUpdate(updatedItems);
      }
     console.log(item)
     moengage.track_event(`Remove_From_Cart`, {
      "Phone No": `+91${JSON.parse(Cookies.get("Otp")).MobileNo}`,
     
      "Product ID": item.ProductId,
      "Product Name": item.PorductName,
      "Base Price": item.PriceQuantity[0].Mrp,
      "Promotion Price": item.PriceQuantity[0].Price,
      "Discount Percentage": item.Note,
      "Flavour": item.variant1_value,
      "Size": item.variant2_value,     
      "Screen Name": "Cart Page ",
    });
      fetchCartItems();
    } catch (error) {
      setSessionError(error.message);
    }
  };

  const incrementQuantity = async (itemId,itemD) => {
    console.log(itemD)
    const item = countItems.find((item) => item.id === itemId);
    if (item) {
      const newQuantity = parseInt(item.ProductQuantity, 10) + 1;
      if (newQuantity > 4) {
        if(!toastShown ){
         toast("Cannot add more than 4 Quantity");
         setToastShown(true)
        }
       } else {
       // setAppliedCoupon("")
        await handleQuantityChange(newQuantity, itemId,itemD);
      }
    }
  };

  const decrementQuantity = async (itemId,itemD) => {
    const item = countItems.find((item) => item.id === itemId);
  

    if (item && item.ProductQuantity > 1) {
      const newQuantity = parseInt(item.ProductQuantity, 10) - 1;
      await handleQuantityChange(newQuantity, itemId,itemD);
    //  setAppliedCoupon("")
    }
  };

  const calculateTotalPrice = () => {
    return countItems.reduce(
      (total, item) => total + item.TotalDiscountPrice * item.ProductQuantity,
      0
    );
  };

  if (sessionError) {
    return <div>Error: {sessionError}</div>;
  }

let handleNewTab = (item) =>{
  let productNewTabUrl = `${window.location.origin}/product?type=productvariant&productid=${item.ProductId}&variant1Value=${item.variant1_value?.replace(/ /g, "+")}&variant2Value=${item.variant2_value?.replace(/ /g, "+")}`;
  window.open(productNewTabUrl, "_blank");
}



  return (
    <div className="cart__item__containers">
      {countItems?.map((item) => (
        <div
          key={item.id}
          className="cart__item__content"
          data-line-item={item.id}
        >
          {item.variant1_value === item.variant1_value &&
            item.variant2_value === item.variant2_value && (
              <div className="cart__item__container flex">
                <div className="cart__item__image">
                  {item.Image && item.Image[0] && (
                    <img src={item.Image[0].ImageUrl} alt="cart-item" />
                  )}
                </div>
                <div className="cart__item__contents">
                  <div className="cart__item__title" onClick={()=>handleNewTab(item)}>{item.PorductName}</div>
                  <div className="cart__item__price flex gap5">
                    <div className="actual_price">
                      ₹{item.TotalPrice
                      }
                    </div>
                    {item.PriceQuantity && (
                      <div className="compare_price">
                        <s>₹{item.PriceQuantity[0].Mrp}</s>
                      </div>
                    )}
                    {item.Note && (
                      <div className="cart__item__discounts green">
                        <span>{item.Note}</span>
                      </div>
                    )}
                  </div>
                  <div className="cart__item__variant grey">
                    <p>
                      {" "}
                      {item.variant1_name}: {item.variant1_value}{" "}
                    </p>
                    {item.variant2_name && (
                      <p>
                      {" "}
                      {item.variant2_name === "Size" ? "Size" : ""}:{" "}
                      {item.variant2_value}{" "}
                    </p>
                    )}
                  </div>
                  <div className="cart__item__buttons flex gap10">
                    <div className="cart__quantity">
                      <div className="quantity__selector" data-product={item.ProductId}>
                        <div
                          className="plus__btn"
                          onClick={() => incrementQuantity(item.id,item)}
                          disabled={item.ProductQuantity >=4}

                        >
                          <img src={PlusIcon} alt="plus" />
                        </div>
                        <div className="input__value">
                          {item.ProductQuantity}
                        </div>
                        <div
                          className="minus__btn"
                          onClick={() => decrementQuantity(item.id,item)}
                          disabled={item.ProductQuantity <= 1}
                        >
                          <img src={MinusIcon} alt="minus" />
                        </div>
                      </div>
                    </div>
                    <div
                      className="cart__remove"
                      onClick={() => handleRemoveClick(item.id,item)}
                    >
                      <RemoveButton />
                    </div>
                  </div>
                </div>
              </div>
            )}
        </div>
      ))}
    </div>
  );
};

export default CartItem;

import React from "react";
import SearchIcon from '../../../../assests/icons/Search.svg';

const SearchBar = ({ onSearch }) => {
  return (
    <div className="header__searchbar">
      <input
        type="text"
        placeholder="Search on unFIT Mart."
        className="search-input"
        onChange={(e) => onSearch(e.target.value)}
      />
      <img src={SearchIcon} className="icon icon-search" alt="SearchIcon" />
    </div>
  );
};

export default SearchBar;

import React from "react";
import { useMediaQuery } from "react-responsive";
import ImageComponent from "../image/mainImageComp";

const BrandBannerImages = [
  {
    mobileSrc: "/images/mobile-banner.png",
    desktopSrc: "/images/banner1.png",
    alt: "New Image 1",
  },
];

const BrandBanner = ({ brandDetails }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const isDesktop = useMediaQuery({ query: "(min-width: 768px)" });
  // Default to an empty object if brandDetails is not provided
  return (
    <div className="offer__banner__wrappe padding-top">
      <div className="offer__banner__container brand__details page-width">
        <div className="offer__banner__slider__gap">
          {BrandBannerImages.map((image, index) => (
            <div key={index}>
              {isMobile && brandDetails?.BrandImage && 
                <ImageComponent
                  src={brandDetails?.BrandImage}
                  alt={image.alt}
                  height={"320px"}
                  pageWidth="100%" // Adjust as per your requirement
                />}
             {isMobile && !brandDetails?.BrandImage && <div className="backGround_Grey"></div>}
             

              {isDesktop && brandDetails?.WebBrandImage && <ImageComponent
                  src={brandDetails?.WebBrandImage}
                  alt={image.alt}
                  height={"100%"}
                  pageWidth="1200" // Adjust as per your requirement
                />}
           
           {isDesktop && !brandDetails?.WebBrandImage && <div className="backGround_Grey"></div>}
              
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BrandBanner;

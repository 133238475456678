import React, { useEffect, useRef, useState } from "react";
import { useParams, Link, useLocation, useNavigate } from "react-router-dom";
// import { Helmet } from 'react-helmet';
import Slider from "react-slick";
import ArrowTop from "../../../assests/icons/arrowTop.svg";
import { ReactComponent as Arrow } from "../../../assests/icons/arrowTop.svg";
import  playIcon  from "../../../assests/icons/playIcon.png";
import QuantitySelector from "../../common/quantitySelector";
import PincodeChecker from "../../common/pincodeValidator";
import SubmitButtons from "../../common/submitButton";
import SimilarProduct from "../../common/similarProduct/similarProduct";
import ProductMetaTag from "../../../components/layout/metaTag/metaTagComp";
import ProductBreadcrumbs from "../../common/breadcrumb/breadcrumb";

import "../product/ProductDetail.css";
import {
  Star_icon,
  box_icon,
  c_brand,
  g_star,
  location_icon,
  off_01,
  secure_icon,
  truck_icon,
  y_star,
  cross_black,
  logo_orange,
} from "../../../utils/image";

import grey_star from "../../../assests/image/grey_star.svg";

import {
  fetchRegisterId,
  Product_Rating,
  PRODUCT_RATING_LIST,
  PRODUCTDETAIL,
  PROMO_CODE,
  fetchUserAddress,
  fetchUserData,
} from "../../../Constants";
import PdpReview from "../review/PdpReview";
import { toast } from "react-toastify";
import CustomLoader from "../../common/customLoader/customLoader";
import ToastMessage from "../../common/toastMessage/toastMessage";
// import { Helmet, HelmetProvider } from 'react-helmet-async';

// Slider settings

const mobile__settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  vertical: true,
  verticalSwiping: true,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        vertical: true,
        verticalSwiping: true,
      },
    },
    {
      breakpoint: 990,
      settings: {
        vertical: false,
        verticalSwiping: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        vertical: false,
        verticalSwiping: false,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  vertical: true,
  verticalSwiping: true,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        vertical: true,
        verticalSwiping: true,
      },
    },
    {
      breakpoint: 990,
      settings: {
        vertical: false,
        verticalSwiping: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        vertical: false,
        verticalSwiping: false,
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
  ],
};

const ProductDetailNew = ({ setCountItems }) => {
  const { productId, variant1, variant2 } = useParams();
  const [product, setProduct] = useState(null);
  const [PromoCode, setPromoCode] = useState(null);
  const [PromoCodeDetails, setPromoCodeDetails] = useState({
    isOpen: false,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [mainImage, setMainImage] = useState("");
  const [productInStock, setProductInStock] = useState(true);
  const [variant1Value, setVariant1Value] = useState(variant1 || "");
  const [variant2Value, setVariant2Value] = useState(variant2 || "");
  //  const [variant, setVariant] = useState([]);
  // const [filteredVariant2Values, setFilteredVariant2Values] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [isAddReview, setIsAddReview] = useState(false);
  const [isToastMessage, setIsToastMessage] = useState(false);
  const [reviewDetails, setReviewDetails] = useState({
    Rating: 0,
    Review: "",
    Title: "",
  });
  const [reviewError, setReviewError] = useState({});
  const reviewRef = useRef(null);
  const location = useLocation();
  const [reviews, setreviews] = useState([]);
  //const [TotalReview, setTotalReview] = useState();
  //const [Total, setTotal] = useState();
  const [isDescriptionOpen, setIsDescriptionOpen] = useState(true);
  const [isProductInfoOpen, setIsProductInfoOpen] = useState(false);
  const userData = fetchUserData();
  const userAddress = fetchUserAddress();
  const previewPaneRef = useRef(null);
  const zoomerRef = useRef(null);
  const navigate = useNavigate();

  //const [filteredVariant2Optionss, setFilteredVariant2Options] = useState([]);

  const toggleDescription = () => setIsDescriptionOpen(isDescriptionOpen);
  const toggleProductInfo = () => setIsProductInfoOpen(isProductInfoOpen);

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  // Retrieve and decode query parameters
  const ProductId = queryParams.get("productid");
  //let queryData = Array.from(queryParams.entries());
  const Variant1 = decodeURIComponent(
    queryParams.get("variant1Value") || ""
  ).replace(/\+/g, " ");
  const Variant2 = decodeURIComponent(
    queryParams.get("variant2Value") || ""
  ).replace(/\+/g, " ");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [loading]);

  const fetchData = async () => {
    try {
      const payload = new URLSearchParams({
        ProductId: ProductId,
        Variant1Value: Variant1 ? Variant1.replace(/\+/g, " ") : "",
        Variant2Value: Variant2 ? Variant2.replace(/\+/g, " ") : "",
      });

      const response = await fetch(PRODUCTDETAIL, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: payload,
      });

      if (!response.ok) {
        throw new Error(
          `Failed to fetch product details! Status: ${response.status}`
        );
      }

      const data = await response.json();

      setProduct(data);
      if (data?.Result?.[0]) {
        const productItem = data.Result[0];
        if (productItem?.PriceQuantity?.[0]?.CoverImage?.[0]?.image) {
          setMainImage(productItem.PriceQuantity[0].CoverImage[0].image); // Set initial main image
          setProductInStock(
            productItem.PriceQuantity[0].InStock === "yes" ? true : false
          );
        }
        //  setTotalReview(data?.ProductRating?.RatingCount);
        //  setTotal(data?.ProductRating?.Rating);

        // Extract variant values if available
        setVariant1Value(productItem.Variant1Value || "");
        setVariant2Value(productItem.Variant2Value || "");

        // Create a unique array using Variant1Value
        const uniqueVariants = data.VariantDetails.reduce((acc, current) => {
          const existingVariant = acc.find(
            (item) => item.Variant1Value === current.Variant1Value
          );

          if (existingVariant) {
            existingVariant.Variant2Values.push(current.Variant2Value);
          } else {
            acc.push({
              Variant1Value: current.Variant1Value,
              Variant2Values: [current.Variant2Value],
            });
          }

          return acc;
        }, []);

        //  setVariant(uniqueVariants);

        // Set default variant values to the first item in the array
        if (uniqueVariants.length > 0) {
          const defaultVariant = uniqueVariants[0];
          setVariant1Value(defaultVariant.Variant1Value);
          //setFilteredVariant2Values(defaultVariant.Variant2Values);
          setVariant2Value(defaultVariant.Variant2Values[0]);
        }
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData2 = async () => {
      try {
        const payload = new URLSearchParams({
          ProductId: ProductId,
          Variant1Value: Variant1 ? Variant1.replace(/\+/g, " ") : "",
          Variant2Value: Variant2 ? Variant2.replace(/\+/g, " ") : "",
        });

        const response = await fetch(PRODUCTDETAIL, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: payload,
        });

        if (!response.ok) {
          throw new Error(
            `Failed to fetch product details! Status: ${response.status}`
          );
        }

        const data = await response.json();
        setProduct(data);
        if (data?.Result?.[0]) {
          const productItem = data.Result[0];
          if (productItem?.PriceQuantity?.[0]?.CoverImage?.[0]?.image) {
            setMainImage(productItem.PriceQuantity[0].CoverImage[0].image); // Set initial main image
            setProductInStock(
              productItem.PriceQuantity[0].InStock === "yes" ? true : false
            );
          }
          // Extract variant values if available
          setVariant1Value(productItem.Variant1Value || "");
          setVariant2Value(productItem.Variant2Value || "");

          // Create a unique array using Variant1Value
          const uniqueVariants = data.VariantDetails.reduce((acc, current) => {
            const existingVariant = acc.find(
              (item) => item.Variant1Value === current.Variant1Value
            );

            if (existingVariant) {
              existingVariant.Variant2Values.push(current.Variant2Value);
            } else {
              acc.push({
                Variant1Value: current.Variant1Value,
                Variant2Values: [current.Variant2Value],
              });
            }

            return acc;
          }, []);

          //  setVariant(uniqueVariants);

          // Set default variant values to the first item in the array
          if (uniqueVariants.length > 0) {
            //const defaultVariant = uniqueVariants[0];
            setVariant1Value(Variant1);
            //  setFilteredVariant2Values(defaultVariant.Variant2Values);
            setVariant2Value(Variant2);
          }
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    setTimeout(() => {
      fetchData2();
    }, 1000);
  }, [location.search]);

  useEffect(() => {
    const fetchPromoCode = async () => {
      try {
        const RegisterId = fetchRegisterId();
        const payload = new URLSearchParams({
          RegisterId,
        });
        const response = await fetch(PROMO_CODE, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: payload,
        });

        if (!response.ok) {
          throw new Error(
            `Failed to fetch product details! Status: ${response.status}`
          );
        }

        const data = await response.json();
        setPromoCode(data.Result);
      } catch (err) {
        debugger;
        setError(err.message);
      } finally {
        // setLoading(false);
      }
    };

    const fetchReviewData = async () => {
      try {
        const payload = new URLSearchParams({
          ProductId: productId,
          page: 1,
        });

        const response = await fetch(PRODUCT_RATING_LIST, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: payload,
        });

        if (!response.ok) {
          throw new Error(
            `Failed to fetch product details! Status: ${response.status}`
          );
        }

        const data = await response.json();
        setreviews(data.Result);
        // setTotalReview(data.TotalReview);
        // setTotal(data.Total);
      } catch (err) {
        setError(err.message);
      } finally {
        // setLoading(false);
      }
    };
    fetchPromoCode();
    fetchReviewData();
    // fetchData();
  }, [productId, variant1, variant2]);

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return <CustomLoader />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!loading && (!product || !product.Result || !product.Result[0])) {
    return <div>No product found</div>;
  }

  const productItem = product.Result[0];
  const inStock = productItem.PriceQuantity?.[0]?.InStock === "yes";
  const title = productItem.Name || "";
  const images = productItem.PriceQuantity?.[0]?.CoverImage || [];
  const price = productItem.PriceQuantity?.[0]?.ListingPrice || "0";
  const comparePrice = productItem.PriceQuantity?.[0]?.MRP || "0";
  const productNote = productItem.PriceQuantity?.[0]?.Note || "";
  const brandName = productItem.BrandName || "";
  const BrandId = productItem.BrandId || "";
  const variantDetails = product.VariantDetails || [];

  //const uniqueVariantDetails = getUniqueVariant2Values(variantDetails);

  const handleThumbnailClick = (image) => {
    setMainImage(image);
  };

  // let thumbnailImageSelect = () =>{
    
  // }
  let thumbnailImages = document.querySelectorAll(".thumbnail");
  thumbnailImages.forEach((image) => {
    image.addEventListener("click", (e) => {
      document.querySelectorAll(".slick-slide").forEach((slide) => {
        slide.classList.remove("slick-current");
      });

      let selectedThumbImage = e.target.closest(".slick-slide");
      if (selectedThumbImage) {
        selectedThumbImage.classList.add("slick-current");
      }
    });
  });

  let emptyItem = document.querySelector(
    ".size__option__item .option__value.active"
  );
  if (emptyItem?.innerHTML === "") {
    emptyItem?.classList.add("hide");
    document
      .querySelector(".size__options .option__name")
      ?.classList.add("hide");
  }

  const handleVariant1Change = (event) => {
    const selectedVariant1Value = event.target.value;
    setVariant1Value(selectedVariant1Value);
    loaderComponent();

    // Filter Variant2 options based on new Variant1 value
    const group = product.Result[0].GroupVariantDetails.find(
      (group) => group.Variant1Value === selectedVariant1Value
    );

    document.querySelectorAll(".slick-slide").forEach((slide) => {
      slide[0]?.classList.add("slick-current");
    });

    if (group) {
      // setFilteredVariant2Options(group.Variant2Value || []);

      // Set the first variant2 value as default if available
      setVariant2Value(group.Variant2Value[0] || "");

      navigate({
        search: `?type=productvariant&productid=${queryParams.get(
          "productid"
        )}&variant1Value=${encodeURIComponent(
          selectedVariant1Value || ""
        ).replace(/%20/g, "+")}&variant2Value=${encodeURIComponent(
          group.Variant2Value[0] || ""
        ).replace(/%20/g, "+")}`,
      });
    } else {
      // setFilteredVariant2Options([]);
      setVariant2Value(""); // Clear Variant2 if no group is found
    }
  };

  const handleVariant2Change = (value) => {
    setVariant2Value(value);
    loaderComponent();
    document.querySelectorAll(".slick-slide").forEach((slide) => {
      slide[0]?.classList.add("slick-current");
    });

    // Navigate to the updated URL
    navigate({
      search: `?type=productvariant&productid=${queryParams.get(
        "productid"
      )}&variant1Value=${encodeURIComponent(Variant1 || "").replace(
        /%20/g,
        "+"
      )}&variant2Value=${encodeURIComponent(value || "").replace(/%20/g, "+")}`,
    });
  };

  const handleQuantityChange = (newQuantity) => {
    setQuantity(newQuantity);
  };

  let brandUrl = `/brands/${brandName
    .toLowerCase()
    .replace(/[^a-z0-9 ]/gi, "")
    .replace(/ /g, "-")}?id=${BrandId}`;


  const handleScroll = () => {
    reviewRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  const toggleAddReviewPopup = () => {
    setIsAddReview(!isAddReview);
  };

  const handleReviewChange = (stars) => {
    setReviewDetails({
      ...reviewDetails,
      Rating: stars,
    });
    let errors = reviewError;
    delete errors.Rating;
    setReviewError(errors);
  };
  const onReviewChange = (field, value) => {
    reviewDetails[field] = value;
    reviewError[field] = checkValidation(field);
    setReviewDetails({ ...reviewDetails });
    setReviewError({ ...reviewError });
  };
  const checkValidation = (field) => {
    let errorMessage = "";
    const { Title, Review } = reviewDetails;
    if (field === "Title" && Title?.length === 0) {
      errorMessage = "Title is required";
    }
    if (field === "Review" && Review?.length === 0) {
      errorMessage = "Review is required";
    }
    return errorMessage;
  };
  const validationForm = () => {
    let errors = {};
    const { Title, Review, Rating } = reviewDetails;

    if (Title?.length === 0) {
      errors["Title"] = "Title is required";
    }
    if (Review?.length === 0) {
      errors["Review"] = "Review is required";
    }
    if (Rating === 0) {
      errors["Rating"] = "Please select rating";
    }
    for (var key in error) {
      if (error[key] !== "") {
        break;
      }
    }

    setReviewError({ ...errors });
    return !Object.keys(errors)?.length > 0;
  };
  const handleReviewSubmit = async () => {
    if (validationForm()) {
      try {
        const apiUrl = Product_Rating;
        let RegisterId = fetchRegisterId();
        let ProductId = productItem?.id;
        let payload = {
          RegisterId,
          ProductId,
          ...reviewDetails,
        };

        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams(payload).toString(),
        });
        if (!response.ok) {
          throw new Error(
            `Failed to fetch product details! Status: ${response.status}`
          );
        }
        const data = await response.json();
        if (data.Status) {
          toast.success(data.StatusMessage);
        } else {
          toast.error(data.StatusMessage);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        // setLoading(false);
        setIsAddReview(false);
        setReviewDetails({
          Rating: 0,
          Review: "",
          Title: "",
        });
      }
    }
  };

  const trackPageView = () => {
    if (typeof window.fbq === "function") {
      window.fbq("track", "ViewContent", {
        content_ids: [`${ProductId}`], // Example: product ID array
        content_type: "product", // Recommended type
      });
    } else {
      console.warn("Facebook Pixel is not initialized or fbq is not defined.");
    }
  };

  function roundToNearestHalf(num) {
    const integerPart = Math.floor(num);
    const decimalPart = num - integerPart;

    if (decimalPart >= 0.5) {
      return integerPart + 0.5;
    } else if (decimalPart > 0 && decimalPart < 0.5) {
      return integerPart;
    } else {
      return integerPart + (decimalPart >= 0.25 ? 0.5 : 0);
    }
  }

  const togglePromoCodePopUp = (promo) => {
    setPromoCodeDetails((prev) => ({ ...promo, isOpen: !prev.isOpen }));
  };

  const filteredVariant2Options =
    (variantDetails &&
      variantDetails.filter((variant) => variant.Variant1Value === Variant1)) ||
    variantDetails[0].filter((variant) => variant.Variant1Value === Variant1);

  const uniqueVariant1Values = [
    ...new Set(variantDetails.map((v) => v.Variant1Value)),
  ];

  const handleMouseMove = (ev) => {
    const previewPane = previewPaneRef.current;
    const zoomer = zoomerRef.current;

    if (previewPane && zoomer) {
      document.querySelector(".product-info")?.classList.add("cursor__pointer");
      zoomer.style.display = "inline-block";
      const posX = ev.nativeEvent.offsetX
        ? ev.nativeEvent.offsetX
        : ev.pageX - previewPane.offsetLeft;
      const posY = ev.nativeEvent.offsetY
        ? ev.nativeEvent.offsetY
        : ev.pageY - previewPane.offsetTop;
      const zoomFactor = 2;
      zoomer.style.backgroundSize = `${
        previewPane.offsetWidth * zoomFactor
      }px ${previewPane.offsetHeight * zoomFactor}px`;
      zoomer.style.backgroundPosition = `${-posX * zoomFactor}px ${
        -posY * zoomFactor
      }px`;
      zoomer.style.backgroundImage = `url(${mainImage})`;
    }
  };

  const handleMouseLeave = () => {
    const zoomer = zoomerRef.current;
    // const previewPane = previewPaneRef.current;
    if (zoomer) {
      zoomer.style.display = "none";
    }
    document
      .querySelector(".product-info")
      ?.classList.remove("cursor__pointer");
  };

  let loaderComponent = () => {
    let loaderWithOpacity = document.querySelector(".opacity__main__container");
    loaderWithOpacity.classList.add("active");
    setTimeout(() => {
      loaderWithOpacity.classList.remove("active");
    }, 1000);
  };

  let variantId = product.Result[0].PriceQuantity[0].Variant1Id;

  // facebook Meta //
  let play_Video = document.querySelector(".play_label");
  let play_Video_mob = document.querySelector(".play_label.mobile")
  let desktop_video = document.querySelector(".desktop__video");
  let mobile_video = document.querySelector(".mobile__video");
  let handlePlayBtn = () => {
    desktop_video?.play();
    play_Video?.classList.add("hide");
  };

  let mobHandlePlayBtn = () => {
    mobile_video?.play();
    play_Video_mob?.classList.add("hide");
  };

    // Add the 'hide' class when the video starts
    desktop_video?.addEventListener("play", () => {
      play_Video?.classList.add("hide");
    });

    // Remove the 'hide' class when the video ends
    desktop_video?.addEventListener("ended", () => {
      play_Video?.classList.remove("hide");
    });

     // Add the 'hide' class when the video starts
     mobile_video?.addEventListener("play", () => {
      play_Video?.classList.add("hide");
    });

    // Remove the 'hide' class when the video ends
    mobile_video?.addEventListener("ended", () => {
      play_Video?.classList.remove("hide");
    });

  let Product = product.Result[0] || {};
  return (
    <div className="product__container__item">
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Product",
          productID: Product.id || "",
          name: Product.Name || "",
          description: Product.Description || "",
          brand: Product.BrandName || "", // Add URL if available
          image: Product.Media?.[0]?.MediaUrl || "",
          url: `${window.location.href}` || "",
          offers: [
            {
              "@type": "Offer",
              price: Product.PriceQuantity?.[0]?.ListingPrice || "",
              priceCurrency: "INR",
              itemCondition: Product.PriceQuantity?.[0]?.ItemCondition,
              availability: "InStock",
            },
          ],
          additionalProperty: [
            {
              "@type": "PropertyValue",
              propertyID: "item_group_id",
              value: Product.id || "",
            },
          ],
        })}
      </script>
      <ProductMetaTag />
      <div className="page-width">
        <div className="product__breadcrumbs">
          <ProductBreadcrumbs product={Product}/>
        </div>
        <div className="product-detail">
          <div className="product-image">
            <div className="product-image-wrap">
              <div className="prod__imgs mobile" data-attr={title}>
                {!inStock && (
                  <div className="sold_out_label">
                    <h2>Out Of Stock</h2>
                  </div>
                )}

                <Slider {...mobile__settings} className="mobile__slider">
                  {images.length > 0 &&
                    images.map((imag, index) => (
                      <div key={index} className="slider__item">
                        {/* <img
                          src={imag.image}
                          alt={`${Product.Name}`}
                          className="product__img--responsive"
                        /> */}
                      {!imag.image.endsWith("mp4") ? (
                        <img src={imag.image} alt={title} />
                      ) : (
                        <div className="video-thumbnail">
                          <img src={playIcon} alt="play" className="play_label mobile" onClick={mobHandlePlayBtn}/>
                          <video 
                          height="100%"
                          width="100%"
                          src={imag.image}
                          alt={title}
                          className="mobile__video"
                          onClick={mobHandlePlayBtn}
                          controls
                        />
                          </div>       
                        
                      )}
                      </div>
                    ))}
                </Slider>
                <div
                  id="zoomer"
                  ref={zoomerRef}
                  className="scope-square"
                  style={{
                    display: "none",
                    position: "absolute",
                    border: "1px solid black",
                    backgroundSize: "600px 600px",
                    zIndex: 1,
                  }}
                ></div>
                {productNote && (
                  <div className="product__discount">
                    <span>{productNote.replace(/[()]/g, "")}</span>
                  </div>
                )}
              </div>
              <div className="prod__img desktop" data-attr={title}>
                {!inStock && (
                  <div className="sold_out_label">
                    <h2>Out Of Stock</h2>
                  </div>
                )}

                <div
                  ref={previewPaneRef}
                  className="previewPane"
                  style={{
                    backgroundImage: `url(${mainImage})`,
                    height: "400px",
                    width: "400px",
                  }}
                  onMouseEnter={handleMouseMove}
                  onMouseOver={handleMouseMove}
                  onMouseMove={handleMouseMove}
                  onMouseLeave={handleMouseLeave}
                >
                  {!mainImage.endsWith("mp4") ? (
                    <img src={mainImage} alt="product__img" />
                  ) : (
                    <video
                      height="fullwidth"
                      id="video"
                      src={mainImage}
                      onClick={handlePlayBtn}
                      controls
                      className="desktop__video"
                    />
                  )}
                </div>
                {!mainImage.endsWith("mp4") ? (
                  <div
                    id="zoomer"
                    ref={zoomerRef}
                    className="scope-square"
                    style={{
                      display: "none",
                      position: "absolute",
                      border: "1px solid black",
                      backgroundSize: "600px 600px",
                      zIndex: 1,
                    }}
                  ></div>
                ) : (
                  <></>
                )}

                {productNote && (
                  <div className="product__discount">
                    <span>{productNote.replace(/[()]/g, "")}</span>
                  </div>
                )}
              </div>
              <Slider {...settings} className="product__thumbnails">
                {images.length > 0 ? (
                  images.map((image, index) => (
                    <div
                      key={index}
                      className="thumbnail"
                      onClick={() => handleThumbnailClick(image.image)}
                    >
                      {!image.image.endsWith("mp4") ? (
                        <img src={image.image} alt={title} />
                      ) : (
                        <div className="video-thumbnail">
                          <img src={playIcon} alt="play" className="play_label" />
                         <video
                            height="106"
                            width="100"
                            src={image.image}
                            alt={title}
                            controls={false}   // disables controls
                               // prevents the video from loading
                            muted              // mutes the video
                          />
                          </div>       
                      )}
                    </div>
                  ))
                ) : (
                  <div>No images available</div>
                )}
              </Slider>
            </div>
            <div className="pdp_icon_badge">
              <ul>
                <li>
                  <img src={box_icon} alt="Icon" />
                  <h5>Secure Packaging</h5>
                </li>
                <li>
                  <img src={secure_icon} alt="Icon" />
                  <h5>100% Authentic</h5>
                </li>
                <li>
                  <img src={truck_icon} alt="Icon" />
                  <h5>Direct Sourcing</h5>
                </li>
                <li>
                  <img src={c_brand} alt="Icon" />
                  <h5>Certified by Brands</h5>
                </li>
              </ul>
            </div>
          </div>
          <div className="product-info">
            <div className="main__product__title">
              <h1>
                {title.endsWith("|") ? title.slice(0, -1) : title}
                {productItem?.USP1 ? ` | ${productItem?.USP1}` : ""}
                {productItem?.USP2 ? ` | ${productItem?.USP2}` : ""}
                {productItem?.USP3 ? ` | ${productItem?.USP3}` : ""}
                {Variant1 ? ` | ${Variant1}` : ""}
                {Variant2 ? ` | ${Variant2}` : ""}
              </h1>
            </div>
            <div className="store__name">
              <Link to={brandUrl}>
                <span>
                  Visit the{" "}
                  <span className="store__name__text">{brandName}</span> Store
                </span>
                <img
                  src={ArrowTop}
                  alt="Arrow Right"
                  className="right__arrow"
                />
              </Link>
            </div>

            <div className="price_section">
              <div className="main__product__price">
                <div className="pdp_price_main">
                  <div className="actual__price">₹{price}</div>
                  {productNote && (
                    <div className="product__dis">
                      <span>{productNote}</span>
                    </div>
                  )}
                </div>
                {productNote && (
                  <div className="compare__price">
                    <s>₹{comparePrice}</s>
                    (Incl. of all taxes)
                  </div>
                )}
                <div className="product_review" onClick={handleScroll}>
                  <div className="pdp_rv_stars">
                    {Array?.from({ length: 5 }).map((stars, index) => {
                      return (
                        <img
                          key={index}
                          width={21}
                          height={21}
                          src={
                            index <
                            roundToNearestHalf(product?.ProductRating?.Rating)
                              ? Star_icon
                              : g_star
                          }
                          alt={`Star ${index}`}
                        />
                      );
                    })}
                  </div>
                  <div className="pdp_review_count">
                    {product?.ProductRating?.RatingCount}
                  </div>
                </div>
              </div>
              <div className="pdp_review_mian"></div>
            </div>

            {/* Product Variant Option starts */}
            <div className="product-variants">
              <div className="product__variant_item">
                <div className="flavour__options">
                  {uniqueVariant1Values && variant1Value && (
                    <>
                      <label className="option__name">
                        {variantId === 6
                          ? "Flavour"
                          : variantId === 7
                          ? "Size"
                          : ""}
                      </label>
                      <select
                        className="flavour__option__item"
                        value={variant1Value}
                        onChange={handleVariant1Change}
                      >
                        {product.Result[0].GroupVariantDetails.map(
                          (variantItem, index) => (
                            <option
                              key={index}
                              value={variantItem.Variant1Value}
                            >
                              {variantItem.Variant1Value}
                            </option>
                          )
                        )}
                      </select>
                    </>
                  )}
                </div>

                {filteredVariant2Options && variant1Value && variant2Value && (
                  <div className="size__options">
                    <label className="option__name">Size</label>
                    <div className="size__option__item">
                      {product.Result[0].GroupVariantDetails.filter(
                        (group) => group.Variant1Value === variant1Value
                      ).map((group, index) => {
                        const variant2Values = group.Variant2Value;
                        return (
                          <div className="size__option__item" key={index}>
                            {variant2Values.map((value, valueIndex) => (
                              <div
                                className={`option__value ${
                                  variant2Value === value.trim() ||
                                  (!variant2Value && valueIndex === 0)
                                    ? "active orange"
                                    : ""
                                }`}
                                key={valueIndex}
                                onClick={() =>
                                  handleVariant2Change(value.trim())
                                }
                              >
                                {value.trim() || variant2Value}
                              </div>
                            ))}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}

                <QuantitySelector
                  onChange={handleQuantityChange}
                  product={product?.Result[0]}
                />
                <div className="offer_coupon">
                  <div className="pdp_other_section">
                    <label className="pdp_other_label">Offers</label>
                    <div className="pdp_coupon_wrap">
                      <ul>
                        {PromoCode?.map((promo) => (
                          <li key={promo.id}>
                            <div className="coupon_main_card">
                              <div className="coupon_img">
                                <img src={promo?.Image || off_01} alt="" />
                              </div>
                              <div className="coupon_content">
                                <h6>{promo.Title}</h6>
                                <p>{promo.Description}</p>
                                <span>
                                  Minimum order value {promo?.OrderMinPrice}
                                </span>
                                <span>One time per user</span>
                              </div>
                              <span className="tm_cnt">
                                <p onClick={() => togglePromoCodePopUp(promo)}>
                                  *T &C apply
                                </p>
                              </span>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>

                {userAddress && (
                  <div className="address_book_pdp">
                    <div className="main_address">
                      <div className="address_title">
                        <h6>Delivered To</h6>
                        <div className="address_location">
                          <img src={location_icon} alt="Pin Icon" />
                          <p>
                            {userAddress.number}, {userAddress.street}
                            <br />
                            {userAddress.landmark}
                            <br />
                            {userAddress.city}, {userAddress.pincode}
                            <br />
                            {userAddress.state}, {userAddress.country}
                            <br />
                            {userData?.MobileNo}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <PincodeChecker productInStock={productInStock} />
                <SubmitButtons
                  className={`test_stock`}
                  productId={product.id}
                  quantity={quantity}
                  variant1Value={variant1Value || variant1}
                  variant2Value={variant2Value || variant2}
                  product={product}
                  setCountItems={setCountItems}
                  productInStock={productInStock}
                  setIsToastMessage={setIsToastMessage}
                  loaderComponent={loaderComponent}
                  trackPageView={trackPageView}
                />
              </div>
            </div>

            {isToastMessage && <ToastMessage />}
            {/* Product Variant Option ends */}

            {/* Product Description start */}
            <div className="product__description__container">
              <div className="product__description-item">
                <details className="product__desc" open={isDescriptionOpen}>
                  <summary onClick={toggleDescription}>
                    Description
                    <Arrow
                      className={`arrow-icon ${
                        isDescriptionOpen ? "closed" : "open"
                      }`}
                    />
                  </summary>
                  <p>
                    {productItem.Description || "No description available."}
                  </p>
                </details>
                <details className="product__desc" open={isProductInfoOpen}>
                  <summary onClick={toggleProductInfo}>
                    Product Information
                    <Arrow
                      className={`arrow-icon ${
                        isProductInfoOpen ? "open" : "closed"
                      }`}
                    />
                  </summary>
                  {productItem.DescriptionBullets &&
                  productItem.DescriptionBullets.length > 0 ? (
                    productItem.DescriptionBullets.map((item, index) => (
                      <p key={index}>
                        {item.Description || "No details available."}
                      </p>
                    ))
                  ) : (
                    <p>No product information available.</p>
                  )}
                </details>
              </div>
            </div>
            {/* Product Description ends */}
          </div>
        </div>
      </div>
      {/* Banner Slider and Similar Products components */}

      <div className="pdp_pr_img_wrap">
        <div className="page-width">
          <div className="pdp_banner_title">
            <h2>From The Brand</h2>
          </div>
          {productItem?.Media?.map((e) => {
            return (
              <div className="pdp_img" key={e.MediaUrl}>
                <img src={e.MediaUrl} alt="pdp banner" />
              </div>
            );
          })}
        </div>
      </div>

      <div className="pdp_review" ref={reviewRef}>
        <div className="page-width">
          <div className="pdp_review_wrap">
            <div className="review_lefts">
              <PdpReview />

              <div className="add_review">
                <div className="star_rating_icon">
                  <h5>
                    <span>4.6</span> / 5
                  </h5>
                  <div className="write_star_icon">
                    <img src={Star_icon} alt="icon" />
                    <img src={Star_icon} alt="icon" />
                    <img src={Star_icon} alt="icon" />
                    <img src={Star_icon} alt="icon" />
                    <img src={grey_star} alt="icon" />
                  </div>
                </div>
                <span>350 Reviews</span>
              </div>
            </div>

            <div className="review_right">
              <h5>Write Your Product Review</h5>
              <p>
                if you’ve used this product, share your thoughts with other
                customers
              </p>
              {/* {userData && ( */}
              <button className="btn_primary" onClick={toggleAddReviewPopup}>
                Write a Review
              </button>
              {/* )} */}

              <div className="review_list">
                {reviews?.slice(0, 2)?.map((review) => {
                  return (
                    <div key={review.Created} className="review_list_item">
                      <div className="review_icon">
                        <div className="rv_icon_img">
                          <img
                            src={review.UserImage}
                            alt={review.Name}
                            style={{ objectFit: "contain" }}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = logo_orange;
                            }}
                          />
                        </div>
                        <div className="rv_auther">
                          <h6>{review.Name}</h6>
                          <div className="rv_auther_star">
                            {Array?.from({ length: 5 }).map((stars, index) => {
                              return (
                                <img
                                  key={index}
                                  src={index < +review.Rating ? y_star : g_star}
                                  alt={`stars ${index}`}
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = g_star;
                                  }}
                                />
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="review_content">
                        <p>{review.Review}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
              {reviews?.length > 2 && (
                <Link to={`${location.pathname}/review`}>
                  <button className="btn_primary btn_border_btn">
                    View More Review
                  </button>
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>

      {PromoCodeDetails?.isOpen && (
        <div className="popup_main">
          <div className="popup_overlay" onClick={togglePromoCodePopUp}></div>
          <div className="popup_wraper">
            <div className="pop_up_title">
              <h5>Coupon Details</h5>
              <div className="cs_pop_close" onClick={togglePromoCodePopUp}>
                <img src={cross_black} alt="coupon" />
              </div>
            </div>
            <div className="popup_body_wrap">
              <div className="coupon_code">
                <div className="coupon_code_label">
                  <h5>{PromoCodeDetails?.Title}</h5>
                  <p>{PromoCodeDetails?.Description}</p>
                </div>
                <div className="coupon_code_tc">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: PromoCodeDetails?.TermsCondition,
                    }}
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div
        className="popup_main"
        style={{ display: isAddReview ? "flex" : "none" }}
      >
        <div className="popup_overlay"></div>
        <div className="popup_wraper">
          <div className="pop_up_title">
            <div className="cs_pop_close" onClick={toggleAddReviewPopup}>
              <img src={cross_black} alt={"review"} />
            </div>
          </div>
          <div className="popup_body_wrap">
            <div className="review_pop_body">
              <h5>Rate Your Purchase Experience</h5>
              <p>Share your experience to help others</p>

              <div className="review_form">
                <div className="pdp_rv_stars">
                  {Array?.from({ length: 5 }).map((stars, index) => {
                    return (
                      <img
                        key={index}
                        onClick={() => handleReviewChange(index + 1)}
                        src={index < reviewDetails.Rating ? Star_icon : g_star}
                        alt={`Star ${index}`}
                      />
                    );
                  })}
                  {reviewError?.Rating && (
                    <p className="error_msg">{reviewError?.Rating}</p>
                  )}
                </div>
                <div className="input_field">
                  <label>Enter Title</label>
                  <input
                    type="text"
                    onChange={(e) => {
                      onReviewChange("Title", e.target.value.trimStart());
                    }}
                    value={reviewDetails?.Title}
                  />
                  {reviewError?.Title && (
                    <p className="error_msg">{reviewError?.Title}</p>
                  )}
                </div>
                <div className="input_field">
                  <label>Message</label>
                  <textarea
                    rows="4"
                    onChange={(e) => {
                      onReviewChange("Review", e.target.value.trimStart());
                    }}
                    value={reviewDetails?.Review}
                  ></textarea>
                  {reviewError?.Review && (
                    <p className="error_msg">{reviewError?.Review}</p>
                  )}
                </div>
                <div className="submit_review">
                  <button className="btn_primary" onClick={handleReviewSubmit}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <BannerSlider /> */}
      <SimilarProduct
        product={productItem}
        setCountItems={setCountItems}
        setIsToastMessage={setIsToastMessage}
      />
    </div>
  );
};

export default ProductDetailNew;

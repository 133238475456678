// src/common/brands/brandApi.js
import { BRAND_LIST } from "../../../Constants";
let cachedBrands = null;
let isFetching = false;

const fetchBrandsFromApi = async () => {
  try {
    const url = BRAND_LIST;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        // Add any other headers if required
      },
      // body: payload // Uncomment and adjust if you need to send data with POST
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const brandsData = await response.json();

    // Ensure brandsData.Result is valid and contains the expected structure
    if (!brandsData || !brandsData.Result || !Array.isArray(brandsData.Result)) {
      throw new Error('Invalid response format from BrandList API');
    }

    // Transform data as needed, assuming brandsData.Result is an array of objects
    const brands = brandsData.Result.reduce((acc, brand) => {
      const firstLetter = brand.BrandName.charAt(0).toUpperCase();
      if (!acc[firstLetter]) {
        acc[firstLetter] = [];
      }
      acc[firstLetter].push({
        name: brand.BrandName,
        image: brand.BrandImage,
        id: brand.BrandId,
        logo: brand.BrandLogo,
        description: brand.BrandDescription
      });
      return acc;
    }, {});

    // Sort brands alphabetically
    const sortedBrands = {};
    Object.keys(brands)
      .sort()
      .forEach(key => {
        sortedBrands[key] = brands[key].sort((a, b) => a.name.localeCompare(b.name));
      });

    // Cache brands for future calls
    cachedBrands = sortedBrands;
    isFetching = false; // Reset fetching flag

    return sortedBrands;

  } catch (error) {
    console.error('Error fetching brands:', error);
    throw new Error('Error fetching brands');
  }
};

export const BrandList = async () => {
  try {
    if (cachedBrands) {
      return cachedBrands; // Return cached brands if available
    }

    if (!isFetching) {
      isFetching = true; // Set fetching flag to prevent concurrent requests
      return await fetchBrandsFromApi();
    }

    // If already fetching, return a promise that resolves when fetching completes
    return new Promise((resolve, reject) => {
      const checkAndResolve = () => {
        if (!isFetching && cachedBrands) {
          resolve(cachedBrands);
        } else {
          setTimeout(checkAndResolve, 100); // Check again after a short delay
        }
      };
      checkAndResolve();
    });

  } catch (error) {
    console.error('Error fetching brands:', error);
    throw new Error('Error fetching brands');
  }
};


export const fetchAllBrandsFromApi = async () => {
  try {
    const payload = new URLSearchParams({ Type: 'web' });
    const url = BRAND_LIST;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        // Add any other headers if required
      },
      // body: payload // Uncomment and adjust if you need to send data with POST
      body: payload,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const brandsData = await response.json();

    // Ensure brandsData.Result is valid and contains the expected structure
    if (!brandsData || !brandsData.Result || !Array.isArray(brandsData.Result)) {
      throw new Error('Invalid response format from BrandList API');
    }

    // Transform data as needed, assuming brandsData.Result is an array of objects
    const brands = brandsData.Result.reduce((acc, brand) => {
      const firstLetter = brand.BrandName.charAt(0).toUpperCase();
      if (!acc[firstLetter]) {
        acc[firstLetter] = [];
      }
      acc[firstLetter].push({
        name: brand.BrandName,
        image: brand.BrandImage,
        id: brand.BrandId,
        logo: brand.BrandLogo,
        description: brand.BrandDescription
      });
      return acc;
    }, {});

    // Sort brands alphabetically
    const sortedBrands = {};
    isFetching = false; // Reset fetching flag

    return brandsData.Result;

  } catch (error) {
    console.error('Error fetching brands:', error);
    throw new Error('Error fetching brands');
  }
};
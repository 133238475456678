import React, { useState, useEffect, useRef } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { BrandList } from '../../common/brands/brandApi';
import Banner from "./brandBanner";
import "./brandDetail.css";
import Cookies from "js-cookie";
import moengage from "@moengage/web-sdk";
import BrandFilter from "../brands/brandFilter";
import { BRAND_DETAILS } from '../../../Constants';
import BrandMetaTag  from "../../layout/metaTag/metaTagComp";
const BrandDetail = ({ setCountItems }) => {
  const { brandName } = useParams();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const [brandDetails, setBrandDetails] = useState(null);
  const [brandProductsListData, setBrandProductsListData] = useState([]);
  const [brandsDetailsData, setBrandsDetailsData] = useState(null);
  const [brands, setBrands] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const hasFetched = useRef(false);
  const previousId = useRef(id);
;  

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const fetchedBrands = await BrandList(); // Fetch brands using BrandList function
        setBrands(fetchedBrands);
      } catch (error) {
        console.error('Error fetching brands:', error);
      }
    };

    fetchBrands();
  }, []);

  useEffect(() => {
    console.log(brandName)
    const findBrandDetails = () => {
      if (brands) {
        const allBrands = Object.values(brands).flat();
        const foundBrand = allBrands.find(
          brand => brand.name.replace('(', '').replace(')', '').replace(/ /g, '-').toLowerCase() === brandName
        );
        setBrandDetails(foundBrand);
      }
    };

    if (brandName && brands) {
      findBrandDetails();
      if(Cookies.get("Otp")){
      moengage.track_event("Brands_Viewed", {
        "Phone No": `+91${JSON.parse(Cookies.get("Otp")).MobileNo}` ,  
        "Brand Name": brandName,
      
        
  
        });
      }
    }
  }, [brandName, brands]);

  useEffect(() => {
    const fetchBrandsFromApi = async () => {
      try {
        const url = BRAND_DETAILS;
        const payload = {
          BrandId: id,
          Pagination: page,
        };
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: new URLSearchParams(payload).toString(),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const brandsData = await response.json();
        setBrandsDetailsData(brandsData?.BrandDetails);
        setBrandProductsListData((prevData) => {
          const newData = brandsData.Result[0] || [];
          const mergedData = [...prevData, ...newData];
          const uniqueData = mergedData.filter(
            (item, index, self) =>
              index === self.findIndex((t) => t.id === item.id)
          );

          return uniqueData;
        });

        setHasMore(brandsData.Result[0]?.length === 10);
      } catch (error) {
        console.error('Error fetching brands:', error);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      if (previousId.current !== id) {
        // ID has changed, clear the list data
        previousId.current = id;
        setBrandProductsListData([]);
        setPage(1); // Reset page number when ID changes
      }
      fetchBrandsFromApi();
    }
  }, [id, page]);

  const loadMoreBrandProducts = () => {
    if (hasMore && !hasFetched.current) {
      setPage((prev) => prev + 1);
    }
  };

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            loadMoreBrandProducts();
          }
        });
      },
      { threshold: 0 }
    );

    const observeLastProductCard = () => {
      const productCards = document.querySelectorAll(
        ".brand__product-grid .product-card"
      );
      if (productCards.length === 0) return;
      const lastProductCard = productCards[productCards.length - 1];
      intersectionObserver.observe(lastProductCard);
    };

    observeLastProductCard();

    // Cleanup function
    return () => {
      intersectionObserver.disconnect();
    };
  }, [brandProductsListData]);

  return (
    <>
       <BrandMetaTag />
      <div className="brand-detail">
        {/* Display Brand Banner */}
        <Banner brandDetails={brandsDetailsData} />

        {/* Display Brand Logo Section */}
        <div className="brand__detail__Wrapper">
          <div className="page-width">
            <div className='brand__detail__container'>
              <div className="brand__image__content">
                <img src={brandDetails?.logo} alt="brand section" />
              </div>
              <div className="brand__text__content">
                <div className="brand__heading">
                  <h1>{brandDetails?.name}</h1>
                </div>
                <div className="brand__paragraph">
                  {brandDetails?.description}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Display Brand Breadcrumb */}
        {/* <div className="brand__detail__breadcrumb page-width">
         <Breadcrumb />
        </div> */}

        <div className="brand__details__filter" style={{ marginTop: "30px" }}>
          <BrandFilter 
            brandDetails={brandDetails} 
            setCountItems={setCountItems} 
            BrandProductsListData={brandProductsListData} 
            setLoading={setLoading} 
            loading={loading} 
          />
        </div>
      </div>
    </>
  );
};

export default BrandDetail;

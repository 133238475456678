// LoadingCard.js
import React from 'react';
import '../../layout/product/productCard/productCard.css'; // Import your CSS file for styling

const LoadingCard = () => {
  return (
    <div className="movie--isloading card-item">
      <div className="loading-image card-image"></div>
      <div className="loading-content card-content">
        <div className="loading-text-container">
          <div className="loading-main-text"></div>
          <div className="loading-sub-text"></div>
        </div>
        <div className="loading-btn"></div>
      </div>
    </div>
  );
}

export default LoadingCard;

import React from "react";
import { footer_benner, footer_benner_mob } from "../../../utils/image";

const ImageWrap = () => {
  return (
    <div className="hm__image__wrapper__sec">
      <picture>
        <source media="(min-width: 851px)" srcSet={footer_benner} />
        <source media="(max-width: 850px)" srcSet={footer_benner_mob} />
        <img
          src={footer_benner || ""}
          alt="Footer section illustration"
          className="footer-image"
        />
      </picture>
    </div>
  );
};

export default ImageWrap;

import { WEB_PLACE_ORDER, WEB_PLACE_ORDER_STATUS,WEB_ORDER,fetchRegisterId } from "../../../Constants";
const PLACE_ORDER_API_URL = WEB_PLACE_ORDER;

const hostname = window.location.origin;
// const isLocalhost = hostname.includes('localhost');
const isAdmin = hostname.includes('https://unfitmart.com');

const RAZORPAY_KEY = isAdmin
  ? 'rzp_live_4a5W92OaFvKV4d' // Test API Key 
  : 'rzp_test_tM8lG1QCIBtuxH'; // Live API Key

export const loadRazorpayScript = () => {
  return new Promise((resolve, reject) => {
    if (document.querySelector('script[src="https://checkout.razorpay.com/v1/checkout.js"]')) {
      resolve();
      return;
    }
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.onload = () => resolve();
    script.onerror = () => reject(new Error('Failed to load Razorpay script'));
    document.body.appendChild(script);
  });
};

const createOrder = async ({ items, total, navigate, userDataObj, displayAddresss }) => {


  try {
    await loadRazorpayScript();
debugger
    const orderData = {
      RegisterId: userDataObj.id || fetchRegisterId(),
      OrderId: '',
      PromoCode: items.CouponDiscount,
      SubTotal: items.SubTotal || 0,
      arrayTotalDiscountPrice: items.FinalPayableAmount || 0,
      TotalPayableAmount: items.FinalPayableAmount || 0,
      TransactionId: "",
      Address: `${displayAddresss.houseNumberAndStreet},${displayAddresss.landmark},${displayAddresss.city},${displayAddresss.state},${displayAddresss.country}-${displayAddresss.pincode}.`, // Replace with actual data if available  ,
      AddressId: "",
      CurrentLat: 23.08108108108108,
      CurrentLng: 72.52638566558649,
      CouponDiscount: items.CouponDiscount,
      WalletAmount: "",
      PaymentType: "Prepaid",
      Country: "India",
      UsedWalletBalance: "",
      Product: JSON.stringify(items.Result.map(item => ({
        ProductId: item.ProductId || "",
        FinalPriceWithQty: item.FinalPriceWithQty || 0,
        FinalDiscountPriceWithQty: item.FinalDiscountPriceWithQty || 0,
        productVariantId: item.productVariantId || ""
      })))
    };

    // Place order via API
    const response = await fetch(PLACE_ORDER_API_URL, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(orderData),
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Failed to place order! Status: ${response.status}, Message: ${errorText}`);
    }

    const result = await response.json();
    const orderIds = result.Result.order_id;
  

    // Update order status and get transaction ID
    const transactionId = await updateOrderStatus({ orderIds, total,items });

    // Initiate Razorpay payment
    const options = {
      key: `${RAZORPAY_KEY}`,
      amount: total.finalAmount * 100, // Amount in paise
      currency: "INR",
      name: "unFIT",
      description: "Order Payment",
      order_id: transactionId, // Use transaction ID from updateOrderStatus
      handler: async (response) => {
    
        let transactionOrder = response.razorpay_order_id;
        let razorpay_payment_id = response.razorpay_payment_id;

        placeOrderStatus({ transactionOrder, razorpay_payment_id });
        navigate("/thankyou", { state: { orderDetails: result } });
      },
      prefill: {
        name: userDataObj.name,
        email: userDataObj.email,
        contact: userDataObj.phone,
      },
      theme: {
        color: "#3399cc",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();

    return orderIds;

  } catch (error) {
    console.error('Error placing order:', error.message);
    // Handle error (e.g., show error message to user)
  }
};

const updateOrderStatus = async ({ orderIds, total,items }) => {
  const orderStatusApi = WEB_ORDER;
  try {
    const results = await fetch(orderStatusApi, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        OrderIds: orderIds,
        TotalAmount: items.FinalPayableAmount || 0,
      }),
    });
    if (!results.ok) {
      const errorText = await results.text();
      throw new Error(`Failed to update order status! Status: ${results.status}, Message: ${errorText}`);
    }

    const statusResult = await results.json();


    // Return transaction ID for Razorpay
    return statusResult.id;

  } catch (error) {
    console.error('Error updating order status:', error.message);
    throw error; // Re-throw error to be handled by caller
  }
};

const placeOrderStatus = async ({ response, transactionOrder, razorpay_payment_id }) => {
  const orderStatusApi = WEB_PLACE_ORDER_STATUS;
  try {
    const results = await fetch(orderStatusApi, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        TransactionOrderId: transactionOrder, // Use the actual order ID
        TransactionId: razorpay_payment_id
      }),
    });

    if (!results.ok) {
      const errorText = await results.text();
      throw new Error(`Failed to update order status! Status: ${results.status}, Message: ${errorText}`);
    }

    const placeStatusResult = await results.json();
  
  } catch (error) {
    console.error('Error updating order status:', error.message);
    // Handle error (e.g., show error message to user)
  }
};
export default createOrder;

// import fetchUnderMaintenance from "../api/fetchUnderMaintenance";
import MaintenanceLogo from "../assests/icons/Maintenance.svg";
import { ReactComponent as ReloadSvg } from "../assests/icons/reload__icon.svg";
import { logo_orange } from "../utils/image";
function Maintenance() {
//  let result= fetchUnderMaintenance();
  return (
    <div className="maintenance__wrapper">
      <div className="maintenance__logo">
        <img src={logo_orange} className="logo" alt="img logo" onClick={() => window.location.reload()}/>
      </div>
      <div className="maintenance__container">
        <img src={MaintenanceLogo} alt="img maintenance" className="maintenanceLogo"/>
      </div>
      <div className="maintenance__btn" onClick={() => window.location.reload()}>
        <ReloadSvg /> Refresh
      </div>
    </div>
  )
}

export default Maintenance

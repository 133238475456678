import React from 'react';
import BrandMetaTag from "../components/layout/metaTag/metaTagComp";

const Contact = () => {
  return (
    <>
      <BrandMetaTag />
      {/* Move the closing fragment tag (</>) to the correct position */}
      <div className='contact padding-lr20'>
        <div className='page-width contact__page'>
          <b>Get in Touch with Us!</b>
          <p>We're here to help with any questions you may have. Before reaching out, please check our FAQ section—you might find your answer there.</p>
          <p>You can contact us through the following:</p>
          <p>
            <b>Email:</b> <b>support@unfitmart.com</b>
          </p>
          <p>
            <b>Toll-Free Number:</b> <b>1800 202 5157</b>
          </p>
          <p>Working hours: Monday – Saturday, 9:30 am – 6:30pm</p>
          <p>
            <b>Address:</b> 1113 I-Square Corporate Park, Science City, Sola, Ahmedabad-380060, Gujarat
          </p>
          <p>We look forward to assisting you!</p>
        </div>
      </div>
    </>
  );
};

export default Contact;

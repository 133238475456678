import React from "react";
import "./footer.css";
import { Link } from "react-router-dom";

import AppleIcon from "../../../assests/logo/appleStoreIcon.png";
import playStoreIcon from "../../../assests/logo/googlePlayIcon.png";
import { FOOTER_API } from "../../../Constants";
import {
 
  facebook,
  insta,
  linkedin,
  pintrest_icon,
  unfit_white_logo,
  youtube,
} from "../../../utils/image";
import PhoneLogo from "../../../assests/icons/Call.svg";
import MailLogo from "../../../assests/icons/Mail.svg";

export const fetchFooterData = async () => {
  const payload = new URLSearchParams({
    Pagination: "",
    groupCategoryId: "0",
    LevelType: "shopDashboard",
  });

  const response = await fetch(FOOTER_API, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: payload,
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();

  return data || [];
};

const Footer = () => {
  return (
    <footer className="bg-color-black">
      <div className="footer__wrapper page-width">
        <div className="footer__container">
          <div className="footer__logo-items">
            <div className="logo__item">
              <Link to="/">
                <img src={unfit_white_logo} alt="unfit Logo" />
              </Link>
            </div>
            <div className="footer__logoes">
              <div className="playstore__item">
                <Link to="https://play.google.com/store/apps/details?id=com.ext.bolt&hl=en_IN">
                  <img src={playStoreIcon} alt="Google Play Store" />
                </Link>
              </div>
              <div className="apple__item">
                <Link to="https://apps.apple.com/in/app/unfit/id6476141751">
                  <img src={AppleIcon} alt="Apple Store" />
                </Link>
              </div>
            </div>
          </div>
          <div className="footer__about-us">
            <div className="about_us-content">
              <h5>About Us</h5>
              <p>
                Your go-to destination for genuine, high-quality health and
                wellness products. Explore our reliable range and kickstart your
                journey to better health with confidence!
              </p>
            </div>
            <div className="contact_us-content">
              <ul>
                {/* <li>
                <img src={LocationLogo} className="icon" />
                  <div>Isquare Corporate Park, 1114-1116, Science City, Panchamrut
                  Bunglows II, Sola, Ahmedabad, Gujarat 380060</div>
                </li> */}
                <li>
                  <img src={PhoneLogo} className="icon" alt="icon" />
                  <Link to="tel:1800 202 5157">
                    Toll Free Number : 1800 202 5157
                  </Link>
                </li>
                <li>
                  <img src={MailLogo} className="icon" alt="icon"/>
                  <Link to="mailto:support@unfitmart.com">support@unfitmart.com</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer__menus-items">
            <h5>Company</h5>
            <ul className="footer__menu-item">
              <li>
                <Link to="/privacy-policy">Policy and Term</Link>
              </li>
              <li>
                <Link to="/refund-policy">Product Refund Policy</Link>
              </li>
              <li>
                <Link to="/terms-condition">Service Refund Policy</Link>
              </li>
              <li>
                <Link to="/shipping-policy">Shipping Policy</Link>
              </li>
              <li>
                <Link to="/terms-condition">Terms and Condition</Link>
              </li>
              <li>
                <Link to="/contact-us">Contact us</Link>
              </li>
            </ul>
          </div>
          <div className="footer__subscribe-item">
            <div className="footer_social">
              <h5>Let's do it!</h5>
              <ul>
                <li>
                  <a href="https://www.instagram.com/unfitmart/" target="_blank">
                    <img src={insta} alt="instagram" />
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/unfitech/" target="_blank">
                    <img src={linkedin} alt="linkedin" />
                  </a>
                </li>
                {/* <li>
                  <a href="/">
                    <img src={bubble_icon} alt="facebook" />
                  </a>
                </li> */}
                <li>
                  <a href="https://www.facebook.com/unfitapp/" target="_blank">
                    <img src={facebook} alt="facebook" />
                  </a>
                </li>
                <li>
                  <a href="https://www.youtube.com/@unFitmart" target="_blank">
                    <img src={youtube} alt="youtube" />
                  </a>
                </li>
                <li>
                  <a href="https://in.pinterest.com/unFITapp/" target="_blank">
                    <img src={pintrest_icon} alt="pinterest" />
                  </a>
                </li>
              </ul>
            </div>
            {/* <div className="footer_news">
              <h5>Subscribe</h5>
              <p>Subscribe to stay tuned for new web design and latest updates. Let's do it! </p>
              <form className="newslatter">
                <div className="input_field">
                  <input type="email" placeholder="Enter your email Address" />
                </div>
                <div className="input_btn">
                  <button>Subscribe</button>
                </div>
              </form>
            </div> */}
          </div>
        </div>
      </div>
      <div className="footer__copyright-container page-width">
        <div className="footer__copyright caption">
          <small className="copyright__content">
            Copyright © 2024 <Link to="/">EVIS HEALTHCARE PRIVATE LIMITED</Link>{" "}
            . All rights reserved.
          </small>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

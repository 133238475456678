import { useEffect, useState } from "react";
import Imoji from "../../../assests/icons/emoji.png";
import { getCookie } from "../../../utils/cookieUtils";
import { right_icon } from "../../../utils/image";

const UnRegisterTotal = ({ existingCartData,isCartEmpty,checkoutBtn }) => {
  const [total, setTotal] = useState({
    totalAmount: 0,
    finalAmount: 0,
    MRPTotal: 0,
    DiscountOnMRP: 0,
    OfferApplied: 0,
    ShippingHandling: 0,
    finalAmoutAfterDiscount: 0,
  });

  useEffect(() => {
    let MRPTotal = 0;
    let totalSum = 0;
    let totalDiscountedPrice = 0;
    let DiscountOnMRP = 0;
    let OfferApplied = 0;
    let ShippingHandling = 0;
    let finalAmoutAfterDiscount = 0;

    existingCartData?.forEach((item) => {
    
      if(item.productId === 1154 || item.productId === 1155){
        MRPTotal += +item.mrp * item.quantity / 2;
        totalSum += +item.listPrice * item.quantity / 2;
        totalDiscountedPrice += +item.listPrice * item.quantity / 2;
       }else{
        MRPTotal += +item.mrp * item.quantity;
        totalSum += +item.listPrice * item.quantity;
        totalDiscountedPrice += +item.listPrice * item.quantity;
      }
      
    });

    DiscountOnMRP = MRPTotal - totalSum;
    OfferApplied = totalSum - totalDiscountedPrice;

    ShippingHandling = totalDiscountedPrice > 399 ? 0 : 0;
    finalAmoutAfterDiscount = totalDiscountedPrice + ShippingHandling;

    const finalPayableAmount = finalAmoutAfterDiscount;

    setTotal({
      totalAmount: totalSum,
      finalAmount: finalPayableAmount,
      MRPTotal,
      DiscountOnMRP,
      OfferApplied,
      ShippingHandling,
      finalAmoutAfterDiscount,
    });
  }, [existingCartData, existingCartData?.[0]?.quantity]);
  return (
    <>
      <div className="cart__final__total">
        <div className="cart_top_item">
          <div className="cart__amount__pay payble-ammount">
            <div className="cart__final__price__heading ">MRP Total</div>
            <div className="cart__final__price__amount">
              {/* ₹{total.MRPTotal || 0} */}₹{total?.MRPTotal.toFixed(2) || 0}
            </div>
          </div>
          <div className="cart__amount__pay service-fee">
            <div className="cart__final__price__heading ">Discount On MRP</div>
            <div className="cart__final__price__amount discount__price">
              -₹{total.DiscountOnMRP.toFixed(2) || 0}
            </div>
          </div>
          <div className="cart__amount__pay discount">
            <div className="cart__final__price__heading ">Offer Applied</div>
            <div className="cart__final__price__amount discount__price">
              {/* -₹{total.totalDiscount || 0} */}
              -₹{total?.OfferApplied.toFixed(2) || 0}
            </div>
          </div>
          {/* <div className="cart__amount__pay service-fee">
            <div className="cart__final__price__heading ">
              Shipping & Handling
            </div>
            <div className="cart__final__price__amount ">
              ₹
              {total?.ShippingHandling || 0}
            </div>
          </div> */}
          <div className="cart__amount__pay total">
            <div className="cart__final__price__heading">Total</div>
            <div className="cart__final__price__amount discount__price">
              {/* ₹{total.finalAmoutAfterDiscount || 0} */}₹
              {total?.finalAmoutAfterDiscount.toFixed(2) || 0}
            </div>
          </div>
        </div>
        {total.OfferApplied > 0 && (
          <div className="cart__amount__pay saved-amount">
            <div className="save_img">
              <img src={Imoji} alt="imoji" />
            </div>

            <div className="cart__save__amount">
              You have saved ₹{total.OfferApplied.toFixed(2)} on this order
            </div>
          </div>
        )}
      </div>
      <div className="cart__final__container">
        {/* {fetchUserData() ? (
         displayAddresss && displayAddresss.pincode ? (
           <div className="addressDisplay">
             <div className="address_heading">
               <h6>Delivered To</h6>
               <button
                 onClick={handleAddress}
                 className="addressChangeButton "
               >
                 Change
               </button>
             </div>
             <div className="address_display">
               <img
                 src={locationIcon}
                 className="location_icon"
                 alt="locationIcon"
               />

               <div>
                 {""}
                 {displayAddresss.houseNumberAndStreet},
                 {displayAddresss.landmark}, {displayAddresss.city},{" "}
                 {displayAddresss.state}-{displayAddresss.pincode},{" "}
                 {displayAddresss.country}
               </div>
             </div>
           </div>
         ) : (
           <div className="addressDisplay">
             <button
               onClick={handleAddress}
               className="addressDisplayButton "
             >
               <img src={addIcon} className="addIcon"></img>
               <div>Add Address </div>
             </button>
           </div>
         )
       ) : (
         <></>
       )} */}

        <div className="cart_total_main">
          <div className="g_total_title">
            Grand Total <span>₹{total?.finalAmoutAfterDiscount.toFixed(2) || 0}</span>
          </div>
          <div className="cart_checkout_main">
            <div
              className={`submit__buttons btn_primary flex ${
                isCartEmpty ? "" : ""
              }`}
              onClick={checkoutBtn}
              style={{
                opacity: isCartEmpty ? 0.8 : 1,
                cursor:  "pointer",
              }}
            >
              Check Out
              <img src={right_icon} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UnRegisterTotal;

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css'; 
import Maintenance from './pages/Maintenance';
import fetchUnderMaintenance from './api/fetchUnderMaintenance';
import { HelmetProvider } from 'react-helmet-async';



 const result = await fetchUnderMaintenance(); 
//const result = "no" 
const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

root.render(
  <HelmetProvider>
  <React.StrictMode>
    {result === "no" ?  <App /> : <Maintenance /> }
  </React.StrictMode>
  </HelmetProvider>

);

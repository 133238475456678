import React from "react";

import Cookies from "js-cookie";
import { getCookie } from "../../../utils/cookieUtils";
import { toast } from "react-toastify";

function addProductToCookies(
  productName,
  productId,
  quantity,
  listPrice,
  mrp,
  note,
  pvid,
  variant1Value,
  variant2Value,
  image
) {
  let response=true;
  let existingCartData = getCookie("cartData");
  // Check if the cookie data is valid JSON and an array, otherwise initialize an empty array
 
  try {
    existingCartData = JSON.parse(existingCartData);
    if (!Array.isArray(existingCartData)) {
      existingCartData = [];
    }
  } catch (e) {
    existingCartData = []; // If there's an error in parsing, initialize as an empty array
  }

  let newData = existingCartData.filter((item) => item?.pvid == pvid);

  existingCartData = existingCartData.filter(
    (item) => item?.pvid != pvid
  );
  let cartData;
  
if( parseInt(newData[0]?.quantity, 10) + parseInt(quantity, 10) > 4){
 
  toast("Cannot add more than 4 Quantity");
  response= false;

}
  if (newData.length > 0) {
    cartData = {
      productName: newData[0].productName,
      productId: newData[0].productId,
      quantity:
        parseInt(newData[0].quantity, 10) + parseInt(quantity, 10) > 4
          ? 4
          : parseInt(newData[0].quantity, 10) + parseInt(quantity, 10),
      listPrice: newData[0].listPrice,
      mrp: newData[0].mrp,
      note: newData[0].note,
      pvid: newData[0].pvid,
      variant1Value: newData[0].variant1Value,
      variant2Value: newData[0].variant2Value,
      image: newData[0].image,
    };
  } else {
    // Create the new cart data object;

    cartData = {
      productName: productName,
      productId: parseInt(productId, 10),
      quantity: quantity,
      listPrice: listPrice,
      mrp: mrp,
      note: note,
      pvid: pvid,
      variant1Value: variant1Value || "",
      variant2Value: variant2Value || "",
      image: image,
    };
    // Add the new cart data to the array

  
  }
  existingCartData.push(cartData);

  // Store the updated array in the cookie
  Cookies.set("cartData", JSON.stringify(existingCartData));
  return response;
}

export default addProductToCookies;

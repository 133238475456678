import React from 'react';
import Star_icon from "../../../assests/image/Star_icon.svg";

const RatingContainer = () => {
  const ratings = [
    { stars: 5, percentage: '85%' },
    { stars: 4, percentage: '65%' },
    { stars: 3, percentage: '45%' },
    { stars: 2, percentage: '25%' },
    { stars: 1, percentage: '10%' },
  ];

  return (
    <div className="rating-container">
      {ratings.map((rating) => (
        <div key={rating.stars} className="rating">
            <div className="star__icons__review">
                {rating.stars} <img src={Star_icon} alt='stars'/>
            </div>
            <div className="progress-bar">
                <div className="progress" style={{ width: rating.percentage }}></div>
            </div>
        </div>
      ))}
    </div>
  );
};

export default RatingContainer;

import { fetchRegisterId,ADD_TO_WISH_LIST } from "../Constants";

 const addWishList = async (productId) => {
    try {
 

      const response = await fetch(ADD_TO_WISH_LIST, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          RegisterId: fetchRegisterId(),
          ProductId : productId
        
          
        }),
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch cart items! Status: ${response.status}`);
      }

      const result = await response.json();
      console.log(result)
      if(result.Status)
      return result

   
    } catch (error) {
      console.log(error.message);
    }
  };

  export default addWishList
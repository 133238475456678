import React from "react";
import ThankyouImage from "../../../assests/icons/ThankyouImage.svg";
import './Thankyou.css';
import { Link } from "react-router-dom";
function ThankyouPage() {
  return (
    <div className="page-width page-wrapper">
      <div className="thankYouIcon">
        <img src={ThankyouImage} />
        <div className="buttonsWrapper">
            <Link to={'/'} className="home-button"> <button className="home-button">Home</button></Link>
         {" "}
         <Link to={`/account/orders`} className="home-button"> <button className="order-button">View your order</button></Link> 
        </div>
</div>
      </div>



      
     
    
  );
}

export default ThankyouPage;

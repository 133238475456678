import React from "react";
// import './ImageComponent.css';

const ImageComponent = ({ src, alt }) => {
  return (
    <div className="main__image">
      <img
        srcSet={`
          ${src} 165w,
          ${src} 360w,
          ${src} 533w,
          ${src} 720w,
          ${src} 940w,
          ${src} 1066w,
          ${src} 1200w
        `}
        sizes={`
          (min-width: 1200px) 1200px,
          (min-width: 1066px) 1066px,
          (min-width: 940px) 940px,
          (min-width: 720px) 720px,
          (min-width: 533px) 533px,
          (min-width: 360px) 360px,
          100vw
        `}
        src={src}
        alt={alt}
        className="responsive-image motion-reduce"
        loading="lazy"
      />
    </div>
  );
};

export default ImageComponent;

import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../../../assests/logo/unfitLogo.png";
import LoginImage from "../../../../assests/image/login.png";
import "../login/login.css";
import Slider from "react-slick";

import "slick-carousel/slick/slick-theme.css";


const Register = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 3000,
        arrow:false
    };
    return (
        <div className="login__wrapper">
            <div className="login__container page-width pd-lr flex gap10">
                <div className="login__left__content">
                    <div className="login__logo">
                        <img src={Logo} alt="unFit Mart" />
                    </div>
                    <div className="login__main__content">
                        <div className="login__heading">
                            Register
                        </div>
                        <div className="login__sub__heading">
                            Register to access your unFIT account
                        </div>
                        <div className="login__phone__box">
                            <div className="login__phone__head">First Name</div>
                            <input type="text" placeholder="Enter First Name" name="firstName" id="firstName" />
                        </div>
                        <div className="login__phone__box">
                            <div className="login__phone__head">Last Name</div>
                            <input type="text" placeholder="Enter Last Name" name="lastName" id="lastName" />
                        </div>
                        <div className="login__phone__box">
                            <div className="login__phone__head">Email</div>
                            <input type="email" placeholder="Enter Your Email" name="email" id="email" />
                        </div>
                        <div className="login__phone__box">
                            <div className="login__phone__head">Referral Code (Optional)</div>
                            <input type="text" placeholder="Referral Code" name="referralCode" id="referralCode" />
                            <div className="login__paragraph">
                                <input type="checkbox" id="terms" />
                                <label htmlFor="terms">
                                    Accept
                                    <Link to="/terms-condition">
                                        <span className="orange_color"> Terms & Conditions </span>
                                    </Link>
                                    and
                                    <Link to="/privacy-policy">
                                        <span className="orange_color"> Privacy Policy</span>
                                    </Link>
                                </label>
                            </div>
                            <div className="login__submit">
                                <span>Register</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="login__right__content">
                    <div className="login__slider__box no-focus-outline">
                    <Slider {...settings}>
                            <div className="register__slider-item">
                                <img src={LoginImage} alt="Slide 1" />
                            </div>
                            <div className="register__slider-item">
                                <img src={LoginImage} alt="Slide 2" />
                            </div>
                            <div className="register__slider-item">
                                <img src={LoginImage} alt="Slide 3" />
                            </div>
                        </Slider>
                     
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Register;

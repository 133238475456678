import React, { useRef } from 'react';

const OTPInput = ({ length = 4, onChange,setOtp }) => {
  // Create an array of refs for each input
  const inputRefs = useRef([]);

  const handleChange = (e, index) => {
    const { value } = e.target;
    // Get all OTP input boxes


    if(index == 0){
      setOtp(Array(4).fill(""));
    }

    // Only proceed if the value is a digit
    if (/^\d$/.test(value)) {
      // Update the value of the respective input
      onChange(index, value);

      // Move to the next input field if value is valid
      if (index < length - 1 && value) {
        inputRefs.current[index + 1].focus();
      }
    } else if (value === '') {
      // Move to the previous input field if value is deleted
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  return (
    <div style={{ display: 'flex', gap: '8px' }}>
    {[...Array(length)].map((_, index) => (
      <input
        key={index}
        ref={(el) => (inputRefs.current[index] = el)} // Assigning ref for each input box
        type="text"
        maxLength="1"
        inputMode="numeric"
        pattern="[0-9]*"
        onChange={(e) => {
          const value = e.target.value;
          // Allow only numeric characters
          if (/^\d$/.test(value)) {
            handleChange(e, index);  // Handle valid input change
            if (index < length - 1) {
              // Move focus to the next input after entering a valid digit
              inputRefs.current[index + 1].focus();
            }
          } else {
            e.target.value = "";  // Clear invalid input
          }
        }}
        onKeyDown={(event) => {
          // Handling backspace
          if (event.key === "Backspace") {
            // If input is empty and backspace is pressed, move focus to previous input
            if (index > 0 && event.target.value === '') {
              inputRefs.current[index - 1].focus();
              inputRefs.current[index - 1].value = '';  // Clear previous input
              handleChange({ target: { value: '' } }, index - 1);  // Trigger change for previous input
            } else {
              event.target.value = '';  // Clear current input value
              handleChange({ target: { value: '' } }, index);  // Trigger change for current input
            }
          }
        }}
        onKeyPress={(event) => {
          if (!/[0-9]/.test(event.key)) {
            event.preventDefault();  // Prevent non-numeric input
          }
        }}
        onPaste={(e) => {
          e.preventDefault();  // Prevent pasting non-numeric values
        }}
        className="otpBox"
      />
    ))}
  </div>
  
  
  );
};

export default OTPInput;
// src/routes.js
import React, { useState } from "react";
import { Routes, Route } from 'react-router-dom';
import Home from '../home/home';
import CollectionProd from '../components/layout/collection/collectionProduct';
import ProductDetail from '../components/layout/product/ProductDetail';
import CartPage from '../components/layout/cart/cart';
import Login from "../components/layout/customer/login/login";
import Register from "../components/layout/customer/register/register";
import Account from "../components/layout/customer/account/account";
import BrandDetail from '../components/common/brands/brandDetail';
import CategoryDetail from "../components/layout/category/categoryDetails";
import OrderStatus from "../components/layout/checkout/orderHistory/orderStatus";
import SearchFilter from "../components/common/searchBar/searchFilter";
import BrandPage from "../components/common/brandGrid/brandPage";
import PrivacyPolicyPage from "../pages/PrivacyPolicy";
import RefundPolicyPage from "../pages/RefundPolicy";
import ShippingPolicy from "../pages/ShippingPolicy";
import TermsCondition from "../pages/TermsCondition";
import ContactUS from "../pages/Contact";
import AboutUS from "../pages/About";
import BlogPost from "../components/layout/blogs/blogPosts";
import BlogDetail from "../components/layout/blogs/blogDetail";
import ThankyouPage from "../components/layout/checkout/ThankyouPage";
import ReviewDetail from "../components/layout/review/ReviewDetail";
import CheckOutPage from "../components/layout/checkout/checkout";
import BestSellerProductPage from "../components/layout/category/bestSellerCategory";
// import { MegaMenu } from '../components/layout/header/megaMenu/megaMenu';

const AppRoutes = ({setCountItems,changingCategory,displayProductList}) => {
  
  const [orderID_details,setOrderID_details] = useState([]);

  return (
    <Routes>
      
      <Route path="/register" element={<Register />} />
      <Route
        path="/*"
        element={
          <>
            <Routes>
              <Route path="/account" element={<Account />} ></Route>
              <Route path="/account/address" element={<Account />} />
              <Route path="/account/orders" element={<Account setOrderID_details={setOrderID_details} />} />
              <Route path="/account/wishlist" element={<Account />} />
              <Route path="/account/user" element={<Account />} />
              <Route path="/cart" element={<CartPage setCountItems={setCountItems}/>} />
              <Route path="/checkout" element={<CheckOutPage setCountItems={setCountItems}/>} />
              <Route path="order-status/order" element={<OrderStatus orderID_details={orderID_details}/>} />

              

              <Route path="/login" element={<Login />}   />
              <Route path="/" element={<Home setCountItems={setCountItems}/>} />
              <Route path="/collection" element={<CollectionProd />} />
              <Route path="/product" element={<ProductDetail setCountItems={setCountItems} />} />
              <Route path="/product/:ShortURL/:productId/:variant1?/:variant2?/review" element={<ReviewDetail />} />
              <Route path="/brands/:brandName" element={<BrandDetail setCountItems={setCountItems} />} />
              <Route path="/category/:categoryName" element={<CategoryDetail setCountItems={setCountItems} changingCategory={changingCategory}/>}/>
              <Route path="/category/:categoryName/:subCategoryName" element={<CategoryDetail setCountItems={setCountItems} changingCategory={changingCategory}/>}/>
              <Route path="/thankyou" element={<ThankyouPage/>}/>
              <Route path="/products/search" element={<SearchFilter displayProductList={displayProductList} setCountItems={setCountItems}/>}/>
              
              {/* Best Seller Product Page */}
               <Route path="/best-seller" element={<BestSellerProductPage setCountItems={setCountItems}  />} ></Route>

              {/* pages */}
              <Route path="/brands" element={<BrandPage />}></Route>
              <Route path="/privacy-policy" element={<PrivacyPolicyPage />}></Route>
              <Route path="/refund-policy" element={<RefundPolicyPage />}></Route>
              <Route path="/shipping-policy" element={<ShippingPolicy />}></Route>
              <Route path="/terms-condition" element={<TermsCondition />}></Route>
              <Route path="/contact-us" element={<ContactUS />}></Route>
              <Route path="/about-us" element={<AboutUS />}></Route>

               {/* Blogs */}
              <Route path="/blogs/all-blogs" element={<BlogPost />} ></Route>
              <Route path="/blog/:post_id" element={<BlogDetail />} />
            </Routes>
          </>
        }
      />
    </Routes>
  );
};

export default AppRoutes;

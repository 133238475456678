import { Link, useLocation } from "react-router-dom";
import Arrow from "../../../assests/icons/arrowTop.svg";

// Helper function to capitalize the first letter
const capitalizeFirstLetter = (str) => {
	return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

const Breadcrumbs = ({ product }) => {
	const location = useLocation();
	const pathnames = location.pathname.split("/").filter((x) => x);
	let breadcrumbPath = "";

	const getLinkPath = (name, index) => {
		// Custom handling for certain paths
		if (breadcrumbPath.includes("product")) {
			return `/product/${pathnames[index]}/${product.Name}`;
		} else if (breadcrumbPath.includes("category")) {
			return `/category/${pathnames[index]}`;
		} else if (breadcrumbPath.includes("blog")) {
			return "/blogs/all-blogs";
		} else {
			// General path structure for other cases
			return breadcrumbPath;
		}
	};

	return (
		<div className="breadcrumbs">
			<Link to="/">Home</Link>
			{pathnames.map((name, index) => {
				// Accumulate breadcrumb path
				breadcrumbPath += `/${name}`;
				const isLast = index === pathnames.length - 1;

				return isLast ? (
					<span key={breadcrumbPath}>
						<img src={Arrow} alt="Arrow" className="breadcrumb-arrow" /> {capitalizeFirstLetter(name)}
					</span>
				) : (
					<span key={breadcrumbPath}>
						{" "}
						<img src={Arrow} alt="Arrow" className="breadcrumb-arrow" />{" "}
						<Link to={getLinkPath(name, index)}>{capitalizeFirstLetter(name)}</Link>
					</span>
				);
			})}
			{/* Render arrow and product name if on product page */}
			{breadcrumbPath.includes("product") && product && (
				<span key="product-name">
					<img src={Arrow} alt="Arrow" className="breadcrumb-arrow" />{" "}
					<span className="breadcrumb-product-name">{product.Name}</span>
				</span>
			)}
		</div>
	);
};

export default Breadcrumbs;

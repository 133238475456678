// src/api/ProductListApi.js

import { WEBHOMEPAGE } from "../Constants";


const BASE_URL = WEBHOMEPAGE;

let cachedBannerData = null;
let fetchPromise = null;

export const fetchBannerData = async () => {
  if (cachedBannerData) {
    return cachedBannerData;
  }

  if (fetchPromise) {
    return fetchPromise;
  }

  fetchPromise = new Promise(async (resolve, reject) => {
    try {
      const payload = new URLSearchParams({
        Pagination: '',
        groupCategoryId: '',
        LevelType: ''
      });

      const response = await fetch(BASE_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: payload,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      cachedBannerData = data || [];
      resolve(cachedBannerData);
    } catch (error) {
      reject(error);
    } finally {
      fetchPromise = null;
    }
  });

  return fetchPromise;
};

// src/components/header/Header.js
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import "./Header.css";
import { ReactComponent as AccountIcon } from '../../../assests/icons/Profile.svg';
import { ReactComponent as Line } from '../../../assests/icons/line.svg';
import { useNavigate } from "react-router-dom";

// import bag from '../../../assests/icons/cartIconBlack.svg';
import hamburger from "../../../assests/icons/hamburger2.svg";

import ArrowTop from '../../../assests/icons/arrowTop.svg';
import Navigation from "../header/headerNav/nav";
import Search from "../../common/searchBar/searchBar";
import HeaderIcons from "../../layout/header/headerIcons/headerIcons";
import Navigations from "./navigation/Navigation";
import { STAGE_ADMIN } from "../../../Constants";
import { Close_Square, logo_orange, right_icon } from "../../../utils/image";
import MegaMenu from "./megaMenu/megaMenu";
import { fetchRegisterId, fetchUserData } from "../../../Constants";
const Header = ({ countItems, setCountItems, setChangingCategory, setDisplayProductList }) => {
  const [openCategory, setOpenCategory] = useState(false);
  const [openMegamenu, setMegamenu] = useState(false);
  let MenuDrawer = document.querySelector("#menuDrawer");
  let MainBody = document.querySelector("body");
  const navigate = useNavigate();
  const [isSticky, setIsSticky] = useState(false);


  
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 5) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleDrawer = () => {
    openNav();
  };

  let openNav = () => {
    MenuDrawer.style.width = "320px";
    MenuDrawer.classList.add("is-open");
    MainBody.classList.add("minicart-active");
  }

  let closeNav = () => {
    MenuDrawer.classList.remove("is-open");
    MenuDrawer.style.width = "0px";
    MainBody.classList.remove("minicart-active");
    setOpenCategory(false);
    setMegamenu(false);
  }

  let handleCategory = () => {
    setOpenCategory(true);
  }

  let handleCategoryOff = () => {
    setOpenCategory(false);
  }

  let handleMegamenu = () => {
    setMegamenu(true);
  }

  let handleMegamenuOff = () => {
    setMegamenu(false);
  }

  const handleBlog = () => {
    navigate(`/blogs/all-blogs`);
    closeNav();
  };
  
  return (
    <header className={`header__wrapper ${isSticky ? 'sticky' : ''}`}>
      <div className="page-width">
        <div className="header__main">
          <div
            className="hamburger__menu icon icon-hamburger"
            onClick={handleDrawer}
          >
            <img src={hamburger} alt="Menu" />
          </div>
          <div className="header__center-content">
            <Link to="/" className="logo">
              <img src={logo_orange} alt="UnfitMart" />
              <span className="hidden">UnfitMart</span>
            </Link>
            <Navigation />
          </div>
          <div className="header__right">
            <Search setDisplayProductList={setDisplayProductList} />
            <div className="header__icons">
              <HeaderIcons
                countItems={countItems}
                setCountItems={setCountItems}
              />
            </div>
          </div>
          <div className="minicart__overlay" onClick={closeNav}></div>
          <div id="menuDrawer" className="menuDrawer">
            {/* <button className="closebtn" onClick={closeNav}>
              &times;
            </button> */}
            {!openCategory && !openMegamenu && (
              <div className="main_menuDrawer">
                <div className="loginWrapper">
                  <div className="login__btn__mob">
                    <AccountIcon />
                   {fetchRegisterId() ? fetchUserData().firstName || fetchUserData().userName.split(' ')[0] :'Login'} 
                  </div>
                  <div className="close_icon" onClick={closeNav}>
                    <img className="" src={Close_Square} alt="Close Icon"/>
                  </div>
                </div>
                <div className="line__svg">
                  <Line />
                </div>
                <div className="drawerItem" onClick={handleCategory}>
                  <div>Category</div>
                  <img src={ArrowTop} className="leftArrow" alt="Arrow Icon"/>
                </div>

                <div className="drawerItem" onClick={handleMegamenu}>
                  <div>Brands</div>
                  <img src={ArrowTop} className="leftArrow" alt="Arrow Icon"/>
                </div>
                {STAGE_ADMIN && (
                  <div className="drawerItem" onClick={handleBlog}>
                    <div>Blogs</div>
                    <img src={ArrowTop} className="leftArrow" alt="Arrow Icon" />
                  </div>
                )}
              </div>
            )}
            {openCategory && (
              <div className="main_menuDrawer">
                <div className="drawerTitle">
                  <img src={right_icon} className="close imageTurn" onClick={handleCategoryOff} alt="Arrow Icon" />
                  <div>Category</div>
                  <div className="close_icon" onClick={closeNav}>
                    <img className="" src={Close_Square} alt="Close Icon"/>
                  </div>
                </div>
                <Navigations
                  openCategory={openCategory}
                  setChangingCategory={setChangingCategory}
                />
              </div>

            )}
            {openMegamenu &&
              <div className="main_menuDrawer">
                <div className="drawerTitle">
                  <img src={right_icon} className="close imageTurn" onClick={handleMegamenuOff} alt="Close Icon"/>
                  <div>Brands</div>
                  <div className="close_icon" onClick={closeNav}>
                    <img className="" src={Close_Square} alt="Close Icon"/>
                  </div>
                </div>
                <MegaMenu 
                  openMegamenu={openMegamenu}
                />
              </div>
            }
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;

import { fetchRegisterId, WEB_ADDRESS } from "../Constants";

 const addAddress = async (addres) => {
    try {
 

      const response = await fetch(WEB_ADDRESS, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          RegisterId: fetchRegisterId(),
          HouseNumber: addres.building + ' |',
          Street: addres.street,
          LandMark: addres.landmark,
          Country: addres.country,
          State: addres.state,
          City: addres.city,
          PostalCode: addres.pincode,
        }),
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch cart items! Status: ${response.status}`);
      }

      const updatedAddress = await response.json();
      return updatedAddress.Result[0]

   
    } catch (error) {
      console.log(error.message);
    }
  };

  export default addAddress
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ProductCard from '../../layout/product/productCard/productCard';
import '../../layout/product/productCard/productCard.css';
import Loader from '../loader/loadingCard';

import { fetchProducts } from '../../../api/prodApi';
import { ReactComponent as LeftArrow } from '../../../assests/icons/LeftArrow.svg';

const ProductSlide = ({ categoryName, categoryId,setIsToastMessage ,setCountItems}) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 767);
  const isMounted = useRef(false);
  const [currentSelected, setCurrentSelected] = useState([]);


  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      loadProducts(page);
    }
  }, [page]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 767);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const loadProducts = async (page) => {
    setLoading(true);
    try {
      const products = await fetchProducts(categoryId, categoryName, page);
      setProducts(products);
      setLoading(false); // Set loading to false when data is fetched
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false); // Set loading to false on error
    }
  };

  const handleNextClick = () => {
    setPage(prevPage => prevPage + 1); // Increment the page number to fetch next set of products
  };

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    touchThreshold: 100,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1

        }
      }
    ]
  };
  let categoryReplace = encodeURIComponent(categoryName)

  const handleInitialAddToCart = (product) => {
    setCurrentSelected((prev) => {
      if (prev.includes(product.id)) {
        return prev.filter((each) => each !== product.id);
      } else {
        return [product.id];
      }
    });
  };
  return (
    <div className="hm__product-container sports__supplements section_space hm__prod__slider">
      <div className='page-width'>
        <div className="hm__prod__slider-title">
          <h2>{categoryName}</h2>
          <div className="hm__prod__desktop__arrow">
            <Link to={`/category/${categoryReplace}?viewId=${4}`}>View all</Link>
          </div>
          <div className="hm__prod__mobile__arrow"> <Link to={`/category/${categoryReplace}?viewId=${4}`}><LeftArrow /></Link></div>
        </div>
      </div>
      <div className='slider_bg_color'>
        <div className='slider_container cm_arrow_side'>
          {loading ? (
            <div className="loading-spinner">
              <div className='product-list'>
                <Loader />
                <Loader />
                <Loader />
                <Loader />
                <Loader />
              </div>
            </div> // Display loader while fetching data
          ) : (
            isMobile ? (
              <div className="product-lists">
                <div className='product__color__item'>
                  {products.slice(0, 4).map((product) => (
                    product.Type === "product" && (
                      <ProductCard key={product.id} product={product} onAddtoCart={handleInitialAddToCart}
                      currentSelected={currentSelected} 
                      isOutOfStock={product?.VariantDetails?.[0]?.InStock == "no"}
                      setIsToastMessage={setIsToastMessage}
                      setCountItems={setCountItems}
                      from={`Homepage_${categoryName?.replace(" ","_")}_Category_Viewed`}
                      />
                    )
                  ))}
                </div>

                <div className="more__view orange">
                  <Link className="more__view orange"  to={`/category/${categoryReplace}`}>
                    See More
                  </Link>
                </div>
              </div>
            ) : (
              <Slider {...sliderSettings}>
                {products.map((product) => (
                  product.Type === "product" && (
                    <ProductCard key={product.id} product={product} onAddtoCart={handleInitialAddToCart}
                      currentSelected={currentSelected} 
                      isOutOfStock={product?.VariantDetails?.[0]?.InStock == "no"}
                      setIsToastMessage={setIsToastMessage}
                      setCountItems={setCountItems}
                      from={`Homepage_${categoryName?.replace(" ","_")}_Category_Viewed`}
                      />
                  )
                ))}
              </Slider>
            )
          )}
        </div>
      </div>
    </div>
  );
}

export default ProductSlide;

import React, { useEffect, useState } from "react";
import { fetchFooterData } from '../components/layout/footer/footer';
import MetaTag from "../components/layout/metaTag/metaTagComp";


const ShippingPage = () => {
  const [footerData, setFooterData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchFooterData();
        setFooterData(data.Result[2].HTML);
      } catch (error) {
        console.error("Error fetching product data:", error);
      }
    };

    fetchData();
  }, []);

  if (!footerData) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <MetaTag />
      <div className='shipping-policy page-width'>
        <h2> Shipping Policy </h2>
        <div dangerouslySetInnerHTML={{ __html: footerData }} />
      </div>
    </>
  );
};

export default ShippingPage;


import React, { useEffect, useState } from "react";
import RemoveButton from "../../common/removeButton";
import PlusIcon from "../../../assests/icons/plus.svg";
import MinusIcon from "../../../assests/icons/minus.svg";
import { getCookie } from "../../../utils/cookieUtils";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { left_icon } from "../../../utils/image";
import { Link } from "react-router-dom";

let toastShown = false;
function UnRegisterCart({ cookieCartItems, setCookieCartItems,setCountItems }) {
  let storedCartData = getCookie("cartData");
  try {
    // Parse the JSON string back into an array of objects
    storedCartData = JSON.parse(storedCartData);
    // Check if it's an array, just to be safe
    if (Array.isArray(storedCartData)) {
      // Loop through the cart data and log each item
      storedCartData.map((item, index) => {
 
      });
    } else {
  
    }
  } catch (e) {

  }

  useEffect(() => {
    setCookieCartItems(storedCartData);

  }, []);

  const incrementQuantity = (itemId) => {
   
    let existingCartData = JSON.parse(getCookie("cartData"));
   
    existingCartData.map((item) => {
      if (item.pvid == itemId) {
        if (item.quantity > 3) {
          if(!toastShown ){
            toastShown = true
           toast("Cannot add more than 4 Quantity");
          }
        } else {
            item.quantity = parseInt(item.quantity, 10) + 1;
        }
      }
    });
    Cookies.set("cartData", JSON.stringify(existingCartData));
    setCookieCartItems(existingCartData);
    setCountItems(prev => parseInt(prev) +1)
  };
  const decrementQuantity = (itemId) => {
    let existingCartData = JSON.parse(getCookie("cartData"));
    existingCartData.map((item) => {
      

      if (item.pvid == itemId) {
        item.quantity =
          parseInt(item.quantity, 10) - 1 > 0
            ? parseInt(item.quantity, 10) - 1
            : item.quantity;
      }
    });
    Cookies.set("cartData", JSON.stringify(existingCartData));
   
    setCountItems(prev => parseInt(prev) -1)
    setCookieCartItems(existingCartData);
  };
  const handleRemoveClick = (itemId) => {
    let existingCartData = JSON.parse(getCookie("cartData"));
    let removeItemCartList = existingCartData.filter(
      (item) => item.pvid !== itemId
    );
    if (removeItemCartList.length === 0) {
  
      Cookies.remove("cartData");
      window.location.reload();
    } else {
      setCountItems(removeItemCartList.length)
      Cookies.set("cartData", JSON.stringify(removeItemCartList));
    }

    setCookieCartItems(removeItemCartList);
  };


const handleRemoveAll = () =>{

  Cookies.remove('cartData');
  window.location.reload();
}

let handleNewTab = (item) =>{
  let productNewTabUrl = `${window.location.origin}/product?type=productvariant&productid=${item.productId}&variant1Value=${item.variant1Value?.replace(/ /g, "+")}&variant2Value=${item.variant2Value?.replace(/ /g, "+")}`;
  window.open(productNewTabUrl, "_blank");
}

  return (
    <div>
    <div className="cart__item__containers">
      {cookieCartItems.map((item, index) => (
        <div key={index} className="cart__item__content" data-line-item={index}>
          {item.variant1_value === item.variant1_value &&
            item.variant2_value === item.variant2_value && (
              <div className="cart__item__container flex">
                <div className="cart__item__image">
                  {item.image && <img src={item.image} alt="cart-item" />}
                </div>
                <div className="cart__item__contents">
                  <div className="cart__item__title" onClick={()=>handleNewTab(item)}>{item.productName}</div>
                  <div className="cart__item__price flex gap5">
                    <div className="actual_price">₹{item.listPrice}</div>
                    {item.mrp && (
                      <div className="compare_price">
                        <s>₹{item.mrp}</s>
                      </div>
                    )}
                    {item.note && (
                      <div className="cart__item__discounts green">
                        <span>{item.note}</span>
                      </div>
                    )}
                  </div>
                  <div className="cart__item__variant grey">
                    <p> {item.variant1Value} </p>
                    <p> {item.variant2Value} </p>
                  </div>
                  <div className="cart__item__buttons flex gap10">
                    <div className="cart__quantity">
                      <div className="quantity__selector">
                        <div
                          className="plus__btn"
                          onClick={() => incrementQuantity(item.pvid)}
                          // disabled={item.quantity=== 4}
                          disabled={item.quantity >= 4}

                        >
                          <img src={PlusIcon} alt="plus" />
                        </div>
                        <div className="input__value" data-value={item.qantity}>
                        {item.quantity}

                        </div>
                        <div
                          className="minus__btn"
                          onClick={() => decrementQuantity(item.pvid)}
                          // disabled={item.quantity === 1}
                          disabled={item.quantity === 1}

                        >
                          <img src={MinusIcon} alt="minus" />
                        </div>
                      </div>
                    </div>
                    <div
                      className="cart__remove"
                      onClick={() => handleRemoveClick(item.pvid)}
                    >
                      <RemoveButton />
                    </div>
                  </div>
                </div>
              </div>
            )}
        </div>
      ))}
    </div>
    <div className="cart_bottom_btns">
      <div className="buy__now__btn">
        <Link to={'/'} className="btn_primary buy__now__btn orange">
          <img src={left_icon} />
          Back To Shop
        </Link>
      </div>
      <button className="btn_primary btn_border_btn add__to_cart__btn" onClick={handleRemoveAll}>
        Remove All
      </button>
    </div>
   </div>
  );
}

export default UnRegisterCart;
